import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";

export function Onboard() {
  const location = useLocation();
  return (
    <Box className="w-screen h-screen flex flex-col justify-start items-center p-24">
      <Box className="flex flex-col pb-24">
        <Typography fontSize={42} fontWeight={700}>
          AI_xperts
        </Typography>
        <Typography fontSize={20}>
          your AI copilot for expert interviews
        </Typography>
      </Box>
      <Typography fontSize={32} fontWeight={700}>
        Verify your email
      </Typography>
      <Typography fontSize={20}>
        We sent email to <br /> <strong>{location.state?.email ?? ""}</strong>
        .<br />
        Click the link inside to get started.
      </Typography>
    </Box>
  );
}
