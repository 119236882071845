import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useState, FC } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { uploadInterviewFile } from "../../services/interview";
import useSWRMutation from "swr/mutation";
import { convertWebmToMp3 } from "../../utils/ffmpeg";
import TagManager from "react-gtm-module";
import { LanguageCode } from "../../utils/languageCodes";
import { useToast } from "../../hooks/useToast";

const meetTypeObject = {
  VIRTUAL_MEETING: "VIRTUAL_MEETING",
  IN_PERSON_MEETING: "IN_PERSON_MEETING",
} as const;
type MeetingType = keyof typeof meetTypeObject;

export const AudioRecorder: FC<{ projectId: string; onClose: () => void }> = ({
  projectId,
  onClose,
}) => {
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [openCloseConfirm, setOpenCloseConfirm] = useState(false);
  const [isSettingParticipants, setIsSettingParticipants] = useState<boolean>(
    false
  );
  const [participants, setParticipants] = useState<number>(0);
  const [languageCode, setLanguageCode] = useState<
    keyof typeof LanguageCode | "N/A"
  >("N/A");
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [recordedAudioChunks, setRecordedAudioChunks] = useState<BlobPart[]>(
    []
  );
  const [meetType, setMeetType] = useState<MeetingType>(
    meetTypeObject.VIRTUAL_MEETING
  );
  const [randomId] = useState(crypto.randomUUID());
  const { setToast } = useToast();
  const openCloseConfirmPop = () => setOpenCloseConfirm(true);
  const onCloseConfirm = () => {
    setOpenCloseConfirm(false);
    onClose();
  };
  const onCloseCancel = () => setOpenCloseConfirm(false);
  const playErrorAudioAlert = () => new Audio("/buzz.mp3").play();
  const startRecording = async () => {
    if (!window.MediaRecorder) {
      setToast({
        open: true,
        severety: "warning",
        message: "Your browser doesn't support audio recording.",
      });
      return;
    }
    const audioContext = new AudioContext();
    //init mediastreams hodler
    let microphoneMediaStream: MediaStream | null = null;
    let shareScreenMediaStream: MediaStream | null = null;

    //init base mediStream nodes
    let microphoneNode: MediaStreamAudioSourceNode | null = null;
    let shareScreenNode: MediaStreamAudioSourceNode | null = null;

    function closeAllStreams() {
      microphoneMediaStream?.getTracks().forEach((track) => track.stop());
      microphoneMediaStream = null;
      shareScreenMediaStream?.getTracks().forEach((track) => track.stop());
      shareScreenMediaStream = null;
    }

    try {
      microphoneMediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      //get microphone audio track
      // TODO: at somepoint we need to distnguish between input devices
      const microphoneAudioTracks = microphoneMediaStream.getAudioTracks();
      if (microphoneAudioTracks.length === 0) {
        //alert users that they are not sharing audio
        setToast({
          open: true,
          severety: "error",
          message: "We could not detect audio stream from the microphone",
        });
        closeAllStreams();

        return;
      }
      const microphoneStream = new MediaStream();
      microphoneStream.addTrack(microphoneAudioTracks[0]);
      microphoneNode = audioContext.createMediaStreamSource(microphoneStream);
    } catch (error) {
      console.log("User refused to allow microphone permission");
      //alert users that they are not sharing audio
      setToast({
        open: true,
        severety: "error",
        message: "Please enable permission for accessing Microphone!",
      });
      return;
    }
    if (meetType === meetTypeObject.VIRTUAL_MEETING) {
      try {
        shareScreenMediaStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          video: true,
        });

        const shareScreenAudioTrack = shareScreenMediaStream.getAudioTracks();
        if (shareScreenAudioTrack.length === 0) {
          //alert users that they are not sharing audio
          setToast({
            open: true,
            severety: "error",
            message:
              "We could not detect any sound, you probably forgot to enable audio sharing!",
          });
          await playErrorAudioAlert();
          closeAllStreams();

          alert(
            "we stopped audio recording, because you did not enable audio sharing,  please re-check!"
          );
          return;
        }

        const shareScreenAudioStream = new MediaStream();
        shareScreenAudioStream.addTrack(shareScreenAudioTrack[0]);
        shareScreenNode = audioContext.createMediaStreamSource(
          shareScreenAudioStream
        );
      } catch (err) {
        closeAllStreams();
        setToast({
          open: true,
          severety: "error",
          message:
            "Please share your screen with audio enabled so we can record your meeting!",
        });
        console.error("Error accessing share screen streams", err);
        return;
      }
    }
    const destinationNode = audioContext.createMediaStreamDestination();
    microphoneNode?.connect(destinationNode);
    shareScreenNode?.connect(destinationNode);
    const stream = destinationNode.stream;
    // after capturing audio tracks we init recorder
    const recorder = new MediaRecorder(stream, {
      mimeType: "audio/webm;codecs=opus",
    });

    let audioChunks: BlobPart[] = [];

    recorder.ondataavailable = (event: BlobEvent) => {
      audioChunks.push(event.data);
    };

    recorder.onstop = async () => {
      // stop/close streams
      closeAllStreams();
      // add new chunks to old recorded on in case of resuming
      setRecordedAudioChunks([...recordedAudioChunks, ...audioChunks]);
      const audioWebmBlob = new Blob([...recordedAudioChunks, ...audioChunks], {
        type: "audio/webm",
      });
      const audioUrl = URL.createObjectURL(audioWebmBlob);
      setAudioURL(audioUrl);
      setAudioBlob(audioWebmBlob);
    };
    // handle native stop recording button in screen share bar
    shareScreenMediaStream?.getVideoTracks().forEach((track) =>
      track.addEventListener("ended", () => {
        recorder.stop();
        setIsRecording(false);
      })
    );
    setRecorder(recorder);
    setIsRecording(true);
    recorder.start();
  };

  const stopRecording = () => {
    if (!recorder) return;
    setIsRecording(false);
    recorder.stop();
  };

  const downloadRecording = async (url: string, ext: string = "webm") => {
    const link = document.createElement("a");
    // link.href = audioURL;
    link.href = url;
    link.download = `${randomId}.${ext}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadRecording = async () => {
    if (!audioBlob) return;
    triggerUploadInterview();
    const mp3Blob = await webmToMp3Trigger({ webmBlob: audioBlob }).catch(
      (_err) => {
        setToast({
          open: true,
          severety: "error",
          message:
            "Something went wrong while preparing your audio! we will fallback and use webm format!",
        });
        return audioBlob;
      }
    );

    downloadRecording(
      URL.createObjectURL(mp3Blob),
      mp3Blob.type === "audio/mp3" ? "mp3" : "webm"
    );
  };
  const {
    isMutating: isffmpegProcessing,
    trigger: webmToMp3Trigger,
  } = useSWRMutation(
    () => "ffmpeg_webm_to_mp3",
    async (_, { arg: { webmBlob } }: { arg: { webmBlob: Blob } }) =>
      convertWebmToMp3(webmBlob)
  );
  const {
    isMutating: isUploadingInterview,
    trigger: triggerUploadInterview,
  } = useSWRMutation(
    () => "upload_recorded_audio",
    async () => {
      if (!audioBlob) return;
      const formData = new FormData();
      formData.append("files", audioBlob, `${randomId}.mp3`);
      formData.append("projectId", projectId);
      participants > 0 &&
        formData.append("participants", participants.toString());
      languageCode !== "N/A" && formData.append("languageCode", languageCode);

      return uploadInterviewFile({
        formData,
        onUploadProgress: (_params) => {},
      });
    },
    {
      throwOnError: false,
      onSuccess() {
        TagManager.dataLayer({
          dataLayer: { event: "upload_recording" },
        });
        onClose();
      },
      onError() {
        setToast({
          open: true,
          severety: "error",
          message:
            "Something went wrong while uploading your audio file, please try again with downloaded file later or retry uploading!",
        });
      },
    }
  );
  const handleMeetTypeChange = (event: SelectChangeEvent<MeetingType>) => {
    setMeetType(event.target.value as MeetingType);
  };
  const openSetParticipantPopup = () => setIsSettingParticipants(true);

  if (isffmpegProcessing || isUploadingInterview) {
    return (
      <Box
        className="p-4 flex flex-col gap-2 min-w-[300px] min-h-[200px] justify-center items-center"
        children={
          <>
            <CircularProgress />
            <Typography children="Preparing your audio file..." variant="h6" />
          </>
        }
      />
    );
  }
  // if (isUploadingInterview) {
  //   return (
  //     <Box
  //       className="p-4 flex flex-col gap-2 min-w-[300px] min-h-[200px] justify-center items-center"
  //       children={
  //         <>
  //           <CircularProgress />
  //           <Typography
  //             children="Uploading your interview file..."
  //             variant="h6"
  //           />
  //         </>
  //       }
  //     />
  //   );
  // }
  if (isRecording) {
    return (
      <Box
        className="p-4 flex flex-col gap-2 min-w-[300px] min-h-[200px] justify-center items-center"
        children={
          <>
            <img
              className="w-20 h-20"
              src={"/recording.gif"}
              alt="Recording..."
            />

            <Button
              variant="contained"
              color="secondary"
              className="rounded-full"
              onClick={stopRecording}
            >
              Stop Recording
            </Button>
          </>
        }
      />
    );
  }

  return (
    <Box className="relative  p-4 flex flex-col justify-center gap-2 w-full min-h-[200px] min-w-[300px]">
      <Box className="absolute top-1 right-1 justify-end">
        <IconButton size="small" onClick={openCloseConfirmPop}>
          <CloseOutlined />
        </IconButton>
      </Box>
      {audioURL ? (
        isSettingParticipants ? (
          <>
            <Typography
              variant="h6"
              children="Here you can provide additional information to improve your transcript quality."
            />
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="label">Number of participants:</InputLabel>
              <Select
                value={participants}
                onChange={(e) => setParticipants(Number(e.target.value))}
                displayEmpty
                labelId="label"
                label="Number of participants:"
              >
                <MenuItem value={0}>
                  <em>N/A</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={-1}>7+</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="label">Main language:</InputLabel>
              <Select
                value={languageCode}
                onChange={(e) => setLanguageCode(e.target.value as any)}
                displayEmpty
                labelId="label"
                label="Main language:"
              >
                <MenuItem key={"N/A"} value={"N/A"}>
                  <em>N/A</em>
                </MenuItem>
                {Object.entries(LanguageCode).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box className="flex w-full justify-center">
              <Button
                variant="contained"
                color="secondary"
                className="rounded-full mx-8"
                onClick={uploadRecording}
              >
                Upload Interview
              </Button>
            </Box>
          </>
        ) : (
          <>
            <audio controls preload="auto">
              <source src={audioURL} />
            </audio>
            <Button
              variant="outlined"
              color="secondary"
              className="rounded-full"
              onClick={startRecording}
            >
              Continue Recording
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="rounded-full"
              onClick={openSetParticipantPopup}
            >
              Upload Interview
            </Button>
          </>
        )
      ) : (
        //first time recording
        <>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" className="bg-white">
              Meet Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={meetType}
              label="Meet Type"
              onChange={handleMeetTypeChange}
            >
              <MenuItem value={meetTypeObject.VIRTUAL_MEETING}>
                Virtual meeting
              </MenuItem>
              <MenuItem value={meetTypeObject.IN_PERSON_MEETING}>
                In-person meeting
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="secondary"
            className="rounded-full"
            onClick={startRecording}
          >
            Start Recording
          </Button>
        </>
      )}
      <Dialog open={openCloseConfirm} onClose={onCloseCancel} maxWidth={"lg"}>
        <DialogContent className="flex flex-col gap-2 ">
          <Stack
            direction={"column"}
            spacing={3}
            className="px-4 min-w-[600px]"
          >
            <Typography
              className=""
              textAlign={"start"}
              fontSize={16}
              children={`Please confirm that you want to close the recording popup. If you do, your recording will be lost permanently.`}
            />
            <Stack direction={"row"} spacing={1} justifyContent={"end"}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onCloseCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onCloseConfirm}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
