import { LockOutlined } from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { googleIcon } from "../components/icons";
import { Copyright } from "../components/login/SignUpForm";
import { useAuthContext } from "../hooks/useAuth";
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import useSWRMutation from "swr/mutation";
import { useSearchParams } from "react-router-dom";
import { InviteInput } from "../services/auth";
import { z } from "zod";
import { inviteInputSchema } from "../zod/auth";
import { useToast } from "../hooks/useToast";

type SignupSchemaType = z.infer<typeof inviteInputSchema>;
type SignupSchmaError = z.typeToFlattenedError<SignupSchemaType>["fieldErrors"];

export function InvitePage() {
  const { loginWithGoogle, loginwithInvite } = useAuthContext();
  const { toast, setToast } = useToast();
  const [errors, setErrors] = useState<SignupSchmaError>({});
  const [query] = useSearchParams();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // TODO: add validation
    const loginForm = {
      firstName: data.get("firstName")?.toString() ?? "",
      lastName: data.get("lastName")?.toString() ?? "",
      password: data.get("password")?.toString() ?? "",
      confirmPassword: data.get("confirmPassword") ?? "",
    };
    const querys = {
      token: query.get("token") ?? "",
      user_id: query.get("user_id") ?? "",
    };
    const parse = inviteInputSchema.safeParse(loginForm);
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setErrors({});
    triggerLoginWithInvite({ ...loginForm, ...querys });
  };

  const {
    isMutating: isLogingIn,
    trigger: triggerLoginWithInvite,
  } = useSWRMutation(
    "login_with_invite",
    async (_, { arg }: { arg: InviteInput }) => loginwithInvite(arg),
    {
      throwOnError: false,

      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isGoogleLoading,
    trigger: triggerGoogleLogin,
  } = useSWRMutation(
    "login_with_google",
    async (_, { arg }: { arg: { access_token: string } }) =>
      loginWithGoogle(arg),
    { throwOnError: false }
  );
  const googleLogin = useGoogleLogin({
    onSuccess(tokenResponse) {
      triggerGoogleLogin(tokenResponse);
    },
    onError: (err) => {
      setToast({
        open: true,
        severety: "error",
        message:
          err.error_description ?? "Something went wrong try again later!",
      });
    },
  });
  return (
    <Box
      sx={{
        py: 8,
        px: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlined />
      </Avatar>
      <Typography component="h1" variant="h5">
        Invite
      </Typography>
      <Typography component="h1" variant="h6" color={"text.secondary"}>
        To join the project and create an account, please fill out this form.
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Box className="flex flex-row justify-between gap-3">
          <FormControl fullWidth>
            <TextField
              fullWidth
              margin="normal"
              // required
              id="firstName"
              label="First Name"
              name="firstName"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.firstName?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              fullWidth
              margin="normal"
              // required
              id="lastName"
              label="Last Name"
              name="lastName"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.lastName?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
        </Box>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.password?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            fullWidth
            name="confirmPassword"
            label="Confrim Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.confirmPassword?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>

        <Button
          variant="outlined"
          className="border-2 gap-2 mt-2"
          onClick={() => googleLogin()}
        >
          <SvgIcon>{googleIcon}</SvgIcon> Google
        </Button>
        <Copyright sx={{ mt: 5 }} />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isGoogleLoading || isLogingIn}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
