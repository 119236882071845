import { Box } from "@mui/material";
import { AllInterviewsTable } from "../components/AllInterviews/PublicInterviewTable";

export function LibraryPage() {
  return (
    <Box className="p-4 m-0  w-full h-full flex flex-col  ">
      <Box className="relative max-w-full h-full flex flex-col shadow-2xl rounded-md  border-2 bg-white">
        <AllInterviewsTable />
      </Box>
    </Box>
  );
}
