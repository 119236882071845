import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { z } from "zod";
import { FormIndexProps, LOGIN_PAGE_FORMS } from "../../pages/Login";
import { forgetPassword } from "../../services/auth";
import { forgetPasswordInputSchema } from "../../zod/auth";
import { Copyright } from "./SignUpForm";
import { useToast } from "../../hooks/useToast";

type ForgetPasswordSchemaType = z.infer<typeof forgetPasswordInputSchema>;
type ForgetPasswordError = z.typeToFlattenedError<ForgetPasswordSchemaType>["fieldErrors"];
export function ForgetPasswordForm({ changeForm }: FormIndexProps) {
  const { toast, setToast } = useToast();
  const [errors, setErrors] = useState<ForgetPasswordError>({});
  const {
    trigger: triggerForgetPassword,
    isMutating: isLoading,
  } = useSWRMutation(
    "login",
    async (_, { arg }: { arg: { email: string } }) => {
      await forgetPassword(arg);
      setToast({
        open: true,
        message: "Confirmation email hase been sent",
        severety: "success",
      });
    },
    {
      throwOnError: false,
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong!";

        setToast({ message, open: true, severety: "error" });
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginForm = {
      email: data.get("email")?.toString() ?? "",
    };
    const parse = forgetPasswordInputSchema.safeParse(loginForm);
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setErrors({});
    await triggerForgetPassword(parse.data);
  };
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Did you forget you password ?
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            // required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.email?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Reset password
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.LOGIN)}
            >
              Already have an account?
            </Link>
          </Grid>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.SIGNUP)}
            >
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>

        <Copyright sx={{ mt: 5 }} />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
