import { getLocalStorage } from "../hooks/useLocalStorage";
import { axios } from "../utils/axios";

export type AuthTokens = { access: string; refresh: string };

export type InviteInput = {
  user_id: string;
  token: string;
  firstName: string;
  lastName: string;
  password: string;
};
export async function loginWithInvitation({
  firstName,
  lastName,
  password,
  token,
  user_id,
}: InviteInput): Promise<AuthTokens> {
  return (
    await axios.put(
      "/api/auth/invite/",
      {
        firstName,
        lastName,
        password,
      },
      {
        params: {
          token,
          user_id,
        },
      }
    )
  ).data;
}
export async function loginWithEmail(params: {
  email: string;
  password: string;
}): Promise<AuthTokens> {
  return (await axios.post("/api/auth/login/", params)).data;
}
export async function loginWithGoogleAccessToken(params: {
  access_token: string;
}): Promise<AuthTokens> {
  return (await axios.post("/api/auth/google/", params)).data;
}
export async function refreshToken(): Promise<AuthTokens> {
  return (
    await axios.post("/api/auth/token/refresh/", {
      refresh: getLocalRefreshToken(),
    })
  ).data;
}
export async function signUpWithEmail(params: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}): Promise<string> {
  return (await axios.post("/api/auth/signup/", params)).data;
}
export async function confirmEmail(params: {
  user_id: string;
  token: string;
}): Promise<AuthTokens> {
  return (
    await axios.get("/api/auth/email/confirm/", {
      params,
    })
  ).data;
}
export async function forgetPassword(params: { email: string }): Promise<void> {
  return axios.post("/api/auth/password/forget/", params);
}

export async function changeForgottenPassword({
  body,
  query,
}: {
  query: { token: string; user_id: string };
  body: { password: string };
}): Promise<string> {
  return (
    await axios.post("/api/auth/password/forget/confirm/", body, {
      params: query,
    })
  ).data;
}

export async function blackListToken(params: {
  refreshToken: string;
}): Promise<void> {
  return axios.post("/api/auth/logout/", params);
}
export function getLocalAccessToken() {
  return getLocalStorage<{ access: string }>("auth")?.access;
}

export function getLocalRefreshToken() {
  return getLocalStorage<{ refresh: string }>("auth")?.refresh;
}
