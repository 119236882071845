import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Fab,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  InputAdornment,
} from "@mui/material";
import useSWR from "swr";
import { useEffect, useState } from "react";
import {
  InterviewType,
  Project,
  addManager,
  addUser,
  getAllUsersPerProject,
  getNotInvolvedUsers,
  inviteUsersToProject,
  removeUserFromProject,
  updateInterviewVisibility,
  updateProject,
} from "../../services/project";
import { useAuthContext, User } from "../../hooks/useAuth";
import {
  AccountCircle,
  Add,
  Close,
  CloseOutlined,
  ExpandMore,
  Help,
  MoreVert,
  PersonAddAlt1Outlined,
  Save,
} from "@mui/icons-material";
import useSWRMutation from "swr/mutation";
import { isEqual } from "lodash";
import { z } from "zod";
import { updateProjectInputSchema } from "../../zod/project";
import { useToast } from "../../hooks/useToast";
type UpdateProjectInputType = z.infer<typeof updateProjectInputSchema>;
type UpdateProjectError = z.ZodFormattedError<UpdateProjectInputType>;

export const ProjectSettingTab = ({
  project,
  mutateProject,
}: {
  project: Project;
  mutateProject: () => Promise<any>;
}) => {
  const { id } = project;
  const projectId = id.toString();
  const [companies, setCompanies] = useState(project.companies);
  const [abbreviations, setAbbreviations] = useState(project.abbreviations);
  useEffect(() => {
    setUpdateProjectErrors({ _errors: [] });
  }, [companies, abbreviations]);
  const [
    updateProjectErrors,
    setUpdateProjectErrors,
  ] = useState<UpdateProjectError>({ _errors: [] });
  const {
    isMutating: isUpdatingProject,
    trigger: updateProjectTrigger,
  } = useSWRMutation(
    "update_project",
    async (
      _,
      {
        arg: { companies, abbreviations },
      }: {
        arg: {
          companies?: string[];
          abbreviations?: string[];
        };
      }
    ) => {
      await updateProject({ projectId: id, abbreviations, companies });
      await mutateProject();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "project updated successfully!",
          open: true,
          severety: "success",
        });
      },
      onError(err, key, config) {
        console.log(err?.response?.data);
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );

  const {
    data: usersGroups,
    isLoading: isLoadingProjectUsers,
    isValidating: isValidatingProjectUsers,
    mutate: mutateProjectUsers,
    error,
  } = useSWR(`api/project/getAllUsers/${projectId}`, () =>
    getAllUsersPerProject({ projectId: projectId })
  );
  const { user } = useAuthContext();
  const { toast, setToast } = useToast();
  const [isInvitDialogOpen, setIsInvitDialogOpen] = useState<
    "INVITE_ADMIN" | "INVITE_USER" | false
  >(false);
  const {
    isMutating: isAddingManager,
    trigger: addManagerTrigger,
  } = useSWRMutation(
    "add_manger",
    async (
      _,
      {
        arg: { userId },
      }: {
        arg: {
          userId: number;
        };
      }
    ) => {
      await addManager({ projectId: parseInt(projectId), userId });
      await mutateProjectUsers();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "New Manager added successfully!",
          open: true,
          severety: "success",
        });
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const { isMutating: isAddingUser, trigger: addUserTrigger } = useSWRMutation(
    "add_user",
    async (
      _,
      {
        arg: { userId },
      }: {
        arg: {
          userId: number;
        };
      }
    ) => {
      await addUser({ projectId: parseInt(projectId), userId });
      await mutateProjectUsers();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "User added successfully!",
          open: true,
          severety: "success",
        });
      },
      onError(err, key, config) {
        console.log(err?.response?.data);
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isRemovingUser,
    trigger: removeUserTrigger,
  } = useSWRMutation(
    "remove_user",
    async (
      _,
      {
        arg: { userId },
      }: {
        arg: {
          userId: number;
        };
      }
    ) => {
      await removeUserFromProject({ projectId: parseInt(projectId), userId });
      await mutateProjectUsers();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "User removed successfully!",
          open: true,
          severety: "success",
        });
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isInvitingUsers,
    trigger: inviteUsersTrigger,
  } = useSWRMutation(
    "invite_users",
    async (
      _,
      {
        arg: { invitedUsers, isAdmin },
      }: {
        arg: {
          invitedUsers: InvitedUser[];
          isAdmin?: boolean;
        };
      }
    ) => {
      setIsInvitDialogOpen(false);
      await inviteUsersToProject({
        projectId: parseInt(projectId),
        emails: invitedUsers.map((val) => val.email),
        isAdmin,
      });
      await mutateProjectUsers();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "We've just sent the user(s) an invitation.",
          open: true,
          severety: "success",
        });
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isUpdatingVisibility,
    trigger: updateVisibilityTrigger,
  } = useSWRMutation(
    "update_project_type",
    async (
      _,
      {
        arg: { visibility },
      }: {
        arg: {
          visibility: InterviewType;
        };
      }
    ) => {
      await updateInterviewVisibility({ projectId: project.id, visibility });
      // don't wait for mutating project
      // because it might take considerable amount of time
      // instead let it run on the background
      // and update locale state manually
      mutateProject();
      return visibility;
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        setToast({
          message: "Project's interviews visiblity updated successfully!",
          open: true,
          severety: "success",
        });
        setProjectType(data);
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const [projectType, setProjectType] = useState<InterviewType>(
    project.questions
      .flatMap((val) => val.answers)
      .map((val) => val.interview)
      .filter((val) => val.project == project.id)
      .some((val) => val.type === "PR")
      ? "PR"
      : "OR"
  );

  const handleChange = (event: SelectChangeEvent<InterviewType>) => {
    updateVisibilityTrigger({
      visibility: event.target.value as InterviewType,
    });
  };
  const performingAction =
    isAddingManager ||
    isAddingUser ||
    isRemovingUser ||
    isInvitingUsers ||
    isUpdatingVisibility ||
    isUpdatingProject;

  if (isLoadingProjectUsers) {
    return (
      <Box className="w-full  flex h-full overflow-y-auto ">
        <Box className="p-4 w-full  h-full  flex  flex-col justify-center items-center gap-3 ">
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (error || !usersGroups) {
    return (
      <Box className="w-full  flex h-full overflow-y-auto ">
        <Box className="p-8 w-full  h-full  flex  flex-col justify-center items-center gap-3 ">
          <Typography
            fontSize={"30px"}
            fontWeight={"20px"}
            sx={{ color: "text.secondary" }}
          >
            Something went wrong while fetching users! Please try refreshing or
            wait for the automatic revalidation
          </Typography>
          {isValidatingProjectUsers && <CircularProgress />}
        </Box>
      </Box>
    );
  }

  return (
    <Box className="w-full flex h-full overflow-y-auto">
      <Box className="p-4 w-full  h-full  flex  flex-col justify-start gap-3">
        <LinearProgress
          className={`${
            performingAction ? "block" : "hidden"
          } w-full min-h-[4px] `}
          variant="indeterminate"
          color="info"
        />
        {/* Managers Section */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-center items-center border-b-2 border-gray-400 border-solid"
          >
            <Typography variant="h5" sx={{ flexShrink: 0 }}>
              Managers
            </Typography>
            <Tooltip
              title={
                <>
                  Managers have full permission (viewing, editing, deletion) to
                  all project-related data.
                </>
              }
              placement="right"
            >
              <IconButton size="small">
                <Help fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className={`${
                performingAction ? "opacity-40" : ""
              } flex flex-wrap items-center justify-start gap-1`}
            >
              <Tooltip
                title="Invite new manager"
                children={
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => setIsInvitDialogOpen("INVITE_ADMIN")}
                    children={<Add />}
                  />
                }
              />
              {usersGroups.managers.map((val) => (
                <UserItem
                  key={`manager-${val.id}`}
                  user={val}
                  isManager={true}
                  disabled={performingAction}
                  // TODO: finish these
                  onRemoveUser={() => removeUserTrigger({ userId: val.id })}
                  onChangeRole={() => addUserTrigger({ userId: val.id })}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* regural users section*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex justify-start items-center border-b-2 border-gray-400 border-solid"
          >
            <Typography variant="h5" sx={{ flexShrink: 0 }}>
              Regular Users
            </Typography>
            <Tooltip
              title={
                <>
                  Regular users are granted visibility to project data. While
                  they cannot modify existing project data, they have the
                  permission to uploading new interviews.
                </>
              }
              placement="right"
            >
              <IconButton size="small">
                <Help fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className={`${
                performingAction ? "opacity-40" : ""
              } flex flex-wrap items-center justify-start gap-2`}
            >
              <Tooltip
                title="Invite new user"
                children={
                  <Fab
                    size="small"
                    color="secondary"
                    onClick={() => setIsInvitDialogOpen("INVITE_USER")}
                    children={<Add />}
                  />
                }
              />
              {usersGroups.users.map((val) => (
                <UserItem
                  key={`user-${val.id}`}
                  user={val}
                  isManager={performingAction}
                  // TODO: finish these
                  onRemoveUser={() => removeUserTrigger({ userId: val.id })}
                  onChangeRole={() => addManagerTrigger({ userId: val.id })}
                />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="flex flex-row justify-center items-center border-b-2 border-gray-400 border-solid"
          >
            <Typography variant="h5" sx={{ flexShrink: 0 }}>
              Custom Vocabulary
            </Typography>
            <Tooltip
              title={
                <>
                  Please note these formatting guidelines for custom vocabulary:
                  <br />- Each word needs to be in its spoken form and must not
                  contain any digits (for example, "iphone seven" instead of
                  "iphone 7")
                  <br />- Remove all punctuation except apostrophes <br />-
                  Remove spaces between letters in acronyms <br />- You can add
                  a maximum of 1,000 custom vocabulary items, where each of them
                  can contain up to 6 words
                </>
              }
              placement="right"
            >
              <IconButton size="small">
                <Help fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              className={`${
                performingAction ? "opacity-40" : ""
              } flex flex-col  justify-start gap-2`}
            >
              <Box className="border-b flex  flex-row gap-1 justify-start items-center">
                <Typography className="text-left pr-1" variant="h6">
                  Companies
                </Typography>

                {!isEqual(companies, project.companies) && (
                  <>
                    <Button
                      size="small"
                      color="secondary"
                      className="rounded-2xl"
                      variant="contained"
                      onClick={() => {
                        const parse = updateProjectInputSchema.safeParse({
                          companies,
                        });
                        if (!parse.success) {
                          const globalError = parse.error.format();
                          globalError.companies?._errors.length &&
                            setToast({
                              open: true,
                              message: globalError.companies._errors[0],
                              severety: "error",
                            });
                          const flattenErrors = parse.error.flatten();
                          flattenErrors.fieldErrors.companies?.length &&
                            setToast({
                              open: true,
                              severety: "error",
                              message: flattenErrors.fieldErrors.companies[0],
                            });

                          setUpdateProjectErrors(parse.error.format());

                          return;
                        }

                        updateProjectTrigger({ companies });
                      }}
                    >
                      save
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      className="rounded-2xl"
                      variant="outlined"
                      onClick={() => setCompanies([...project.companies])}
                    >
                      cancel
                    </Button>
                  </>
                )}
              </Box>
              <Box className="flex gap-2 flex-row items-center  justify-start flex-wrap">
                <Tooltip
                  title="Add new company"
                  children={
                    <Fab
                      size="small"
                      children={<Add />}
                      color="secondary"
                      onClick={() => setCompanies(["", ...companies])}
                    />
                  }
                />
                {companies.map((company, index) => (
                  <ElementTextField
                    error={!!(updateProjectErrors.companies ?? [])[index]}
                    key={`company-${index}`}
                    value={company}
                    onChange={(newValue) => {
                      setCompanies(
                        companies.map((val, i) =>
                          i === index ? newValue : val
                        )
                      );
                    }}
                    onDelete={() =>
                      setCompanies(companies.filter((_, i) => i !== index))
                    }
                  />
                ))}
              </Box>
            </Box>
            <Box
              className={`${
                performingAction ? "opacity-40" : ""
              } flex flex-col  justify-start gap-2 mt-4`}
            >
              <Box className="border-b flex  flex-row gap-1 justify-start items-center">
                <Typography className="text-left pr-1" variant="h6">
                  Abbreviations
                </Typography>

                {!isEqual(abbreviations, project.abbreviations) && (
                  <>
                    <Button
                      size="small"
                      color="secondary"
                      className="rounded-2xl"
                      variant="contained"
                      onClick={() => {
                        const parse = updateProjectInputSchema.safeParse({
                          abbreviations,
                        });
                        if (!parse.success) {
                          const globalError = parse.error.format();
                          globalError.abbreviations?._errors.length &&
                            setToast({
                              open: true,
                              message: globalError.abbreviations._errors[0],
                              severety: "error",
                            });
                          const flattenErrors = parse.error.flatten();
                          flattenErrors.fieldErrors.abbreviations?.length &&
                            setToast({
                              open: true,
                              severety: "error",
                              message:
                                flattenErrors.fieldErrors.abbreviations[0],
                            });

                          setUpdateProjectErrors(parse.error.format());

                          return;
                        }
                        updateProjectTrigger({ abbreviations });
                      }}
                    >
                      save
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      className="rounded-2xl"
                      variant="outlined"
                      onClick={() =>
                        setAbbreviations([...project.abbreviations])
                      }
                    >
                      cancel
                    </Button>
                  </>
                )}
              </Box>
              <Box className="flex gap-2 flex-row items-center justify-start flex-wrap">
                <Tooltip
                  title="Add new abbreviation"
                  children={
                    <Fab
                      size="small"
                      color="secondary"
                      children={<Add />}
                      onClick={() => setAbbreviations(["", ...abbreviations])}
                    />
                  }
                />
                {abbreviations.map((abbreviation, index) => (
                  <ElementTextField
                    error={!!(updateProjectErrors.abbreviations ?? [])[index]}
                    key={`abbreviations-${index}`}
                    value={abbreviation}
                    onChange={(newValue) => {
                      setAbbreviations(
                        abbreviations.map((val, i) =>
                          i === index ? newValue : val
                        )
                      );
                    }}
                    onDelete={() =>
                      setAbbreviations(
                        abbreviations.filter((_, i) => i !== index)
                      )
                    }
                  />
                ))}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* Visibility section */}
        <Stack
          direction="column"
          alignItems="start"
          justifyContent="start"
          className={`${
            user?.organization.type === "COMP" ? "flex" : "hidden"
          } border border-solid border-gray-300 rounded-lg relative px-4 pt-2`}
        >
          <Typography fontWeight={500} color={"black"} fontSize={20}>
            Visibility
            <Tooltip
              title="Due to compliance reasons, you confirm that the details of your
            interviews can be disclosed to other colleagues of your
            organization (Organization-restricted interviews). If you operate
            under a individual/strict NDA or have been informed about any
            other specific project setup that would prohibit to share
            interview details across your organization, please change the
            project settings accordingly (Project-restricted interviews). No
            information will be shared with other organizations or their
            members under any circumstances"
            >
              <IconButton size="small">
                <Help fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Typography>

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={projectType}
            onChange={handleChange}
            className="px-2"
          >
            <FormControlLabel
              value="OR"
              control={<Radio />}
              label="Organization Restricted"
              disabled={performingAction}
            />
            <FormControlLabel
              value="PR"
              control={<Radio />}
              label="Project Restricted"
              disabled={performingAction}
            />
          </RadioGroup>
        </Stack>
        {/* added space for scroll ref:Project Setings UI: Small updates #235*/}
        <Box className="p-4 w-full" />
      </Box>
      <Dialog
        open={!!isInvitDialogOpen}
        onClose={() => setIsInvitDialogOpen(false)}
      >
        <DialogTitle>Invite new user</DialogTitle>
        <AddUserDialog
          projectId={projectId}
          onInviteUsers={(invitedUsers) =>
            inviteUsersTrigger({
              invitedUsers,
              //specifiy invited uers role
              isAdmin: isInvitDialogOpen === "INVITE_ADMIN",
            })
          }
        />
      </Dialog>
    </Box>
  );
};
const filter = createFilterOptions<{
  email: string;
  name: string;
  invite: boolean;
}>();
type InvitedUser = { email: string; name: string; invite: boolean };
const AddUserDialog = ({
  projectId,
  onInviteUsers,
}: {
  projectId: string;
  onInviteUsers: (params: InvitedUser[]) => any;
}) => {
  const { data: potentialUsers, isLoading, isValidating } = useSWR(
    `api/project/getNotEnvolvedUsers/${projectId}`,
    async () =>
      (await getNotInvolvedUsers({ projectId })).map((val) => ({
        email: val.email,
        name: val.profile
          ? `${val.profile.first_name} ${val.profile.last_name}`
          : "Invited Guest",
        invite: false,
      })),
    {}
  );
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

  return (
    <Box className="flex flex-col justify-center items-center p-0 bg-white rounded border ">
      <LinearProgress
        className={`${isLoading || isValidating ? "block" : "hidden"} w-full`}
      />
      <Stack className="min-h-[400px] p-4" spacing={2}>
        <Typography variant="subtitle1">
          Start typing email, or name to add a user to your project. If the user
          you trying to add is not registered an invitation link will be sent to
          the provided email.
        </Typography>
        <Box className="w-full flex justify-center gap-2">
          <Autocomplete
            disabled={isLoading}
            multiple
            value={invitedUsers}
            options={potentialUsers ?? []}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            sx={{ width: 400 }}
            freeSolo
            id="invite_new_user"
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                (event as React.KeyboardEvent).key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              // TODO: add email validation when value is type of string
              //  TODO: discuss if domain validation should happen on frontend or backend
              setInvitedUsers(
                newValue.map((val) =>
                  typeof val === "string"
                    ? { email: val, invite: true, name: "" }
                    : val
                )
              );
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              // const isExisting = options.some(
              //   (option) => inputValue === option.email
              // );
              // if (inputValue !== "" && !isExisting) {
              //   filtered.push({
              //     inputValue,
              //     email: `Add "${inputValue}"`,
              //   });
              // }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              return `${option.email} ${option.name}`;
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.invite ? `${option.email}(GUEST)` : option.name}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Box className="flex flex-col grow">
                  <Typography
                    textAlign={"start"}
                  >{`${option.name}`}</Typography>
                  <Typography
                    textAlign={"start"}
                    sx={{ color: "text.secondary" }}
                  >
                    {option.email}
                  </Typography>
                </Box>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Email or user name" />
            )}
          />
          <Button
            disabled={isLoading || invitedUsers.length === 0}
            variant="outlined"
            className="p-1"
            onClick={() => onInviteUsers(invitedUsers)}
          >
            <PersonAddAlt1Outlined />
            Invite
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

const UserItem = ({
  user,
  isManager,
  disabled = false,
  onChangeRole,
  onRemoveUser,
}: {
  user: User;
  isManager: boolean;
  disabled?: boolean;
  onChangeRole?: () => any;
  onRemoveUser?: () => any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className="flex gap-1 p-1 min-w-[150px] justify-start items-center border border-solid">
      <Avatar
        variant="square"
        className="bg-transparent p-0   text-black "
        children={<AccountCircle sx={{ fontSize: 32 }} />}
      />
      <Box className="flex flex-col grow">
        <Typography variant="h6" textAlign={"start"} fontSize={14}>
          {user.profile
            ? `${user.profile.first_name} ${user.profile.last_name}`
            : "Invited Guest"}
        </Typography>
        <Typography
          fontSize={12}
          textAlign={"start"}
          sx={{ color: "text.secondary" }}
        >
          {user.email}
        </Typography>
      </Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isManager ? (
          //  actions can be appliead to manager only

          <MenuItem
            key={"Make Regural member"}
            // selected={option === "Pyxis"}
            onClick={() => {
              onChangeRole && onChangeRole();
              handleClose();
            }}
          >
            Make Regural member
          </MenuItem>
        ) : (
          //  actions can be applied to normal users

          <MenuItem
            key={"Make Manager"}
            // selected={option === "Pyxis"}
            onClick={() => {
              onChangeRole && onChangeRole();
              handleClose();
            }}
          >
            Make Manager
          </MenuItem>
        )}
        <Divider sx={{ my: 0.5 }} />
        {/* Dangerous actions */}
        <MenuItem
          key={"Remove User from project"}
          // selected={option === "Pyxis"}
          onClick={() => {
            onRemoveUser && onRemoveUser();
            handleClose();
          }}
        >
          <span className="text-red-500 font-semibold">
            Remove User from project
          </span>
        </MenuItem>
      </Menu>
    </Card>
  );
};
function ElementTextField({
  value,
  onChange,
  onDelete,
  error,
  label,
}: {
  value: string;
  onChange: (newValue: string) => any;
  onDelete: () => any;
  error: boolean;
  label?: string;
}) {
  return (
    <Box className=" flex justify-start">
      <TextField
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton children={<Close />} onClick={onDelete} />
            </InputAdornment>
          ),
        }}
        error={error}
        label={label}
      />
    </Box>
  );
}
