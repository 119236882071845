import { Typography, TypographyProps } from "@mui/material";
import { parse } from "marked";

export const MarkdownTypography = ({
  text,
  ...props
}: { text: string } & TypographyProps) => (
  // Use marked to parse the Markdown text and convert it into HTML
  <Typography
    {...props}
    dangerouslySetInnerHTML={{
      // async: false make sure parsing return string
      __html: parse(text, { async: false }) as string,
    }}
    sx={{
      "& table": { borderCollapse: "collapse" },
      "& tr": { borderBottom: 1 },
      "& ul": {
        display: "block",
        listStyleType: "disc",
        marginBlockStart: "1em",
        marginBlockEnd: "1em",
        marginInlineStart: 0,
        marginInlineEnd: 0,
        paddingInlineStart: "40px",
        unicodeBidi: "isolate",
      },
      "& li": {
        display: "list-item",
      },
      ...props.sx,
    }}
  />
);
