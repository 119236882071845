import SendIcon from "@mui/icons-material/Send";
import { Box, Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { Prompt, answerQuestionByToolCalls } from "../../services/answer";
import { CosmoInterview } from "../../services/project";
import { AnimatedMessageItem, StreamedMessageItem } from "../ChatTextElement";
import { InterviewDetails } from "./InterviewDetails";

export type MessageRole = "user" | "assistant" | "tool";
export type StreamType = "STATIC" | "USER" | "STREAM";
export type Message = {
  visible?: boolean;
  // content: string;
  left: boolean;
  isStreaming: boolean;
  streamType: StreamType;
  editable?: boolean;
  onEdit?: (newMessage: Partial<Message>) => any;
  onStreamingEnd?: () => any;
} & Prompt;
export type Step = {
  sentMessage: boolean;
  onSendMessage: () => any;
  onStartStep: () => any;
};

const STEPS_CHOICES = {
  WRITE_QUESTION: "WRITE_QUESTION",

  ANSWER_QUESTION: "ANSWER_QUESTION",
} as const;

type StepsChoiceType = keyof typeof STEPS_CHOICES;
export function InsightChat({ isVisible }: { isVisible: boolean }) {
  const { id } = useParams();
  const [currentStepIndex, setCurrentStepIndex] = useState<StepsChoiceType>(
    STEPS_CHOICES.WRITE_QUESTION
  );
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [selectedInterview, setSelectedInterview] = useState<CosmoInterview>();
  const bottomDiv = useRef<HTMLDivElement>(null);

  const { trigger: answerQuestionWithToolsTrigger } = useSWRMutation(
    "answer_question_with_tools",
    () => {
      // Trigger a little animation to indicate loading message to user
      // using flicker animation from empty "STREAM" message
      setMessages([
        ...messages,
        {
          role: "assistant",

          content: "",
          isStreaming: true,
          left: true,
          streamType: "STREAM",
          editable: false,
        },
      ]);

      return answerQuestionByToolCalls({
        messages,
        projectId: id ? parseInt(id) : undefined,
        onMessageStream(prompt) {
          setMessages([
            ...messages,
            {
              role: "assistant",

              content: prompt.content,
              isStreaming: true,
              left: true,
              streamType: "STREAM",
              editable: false,
            },
          ]);
        },
      });
    },
    {
      throwOnError: false,
      onSuccess(data) {
        setMessages([
          ...messages,
          {
            ...data,
            isStreaming: false,
            left: true,
            editable: false,
            onStreamingEnd() {},
            streamType: "STATIC" as const,
          },
        ]);
        setCurrentStepIndex(STEPS_CHOICES.WRITE_QUESTION);
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setMessages([
          ...messages,
          {
            role: "assistant",
            content: message,
            streamType: "STATIC",
            left: true,
            isStreaming: false,
          },
        ]);
        setCurrentStepIndex(STEPS_CHOICES.WRITE_QUESTION);
      },
    }
  );
  const initChat = () => {
    setMessages([
      {
        role: "assistant",
        content: "Hi there! How can I assist you today?",
        left: true,
        isStreaming: true,
        onStreamingEnd() {
          setMessages((prev) =>
            prev.map((val, mapIndex) =>
              mapIndex !== 0 ? val : { ...val, isStreaming: false }
            )
          );
        },
        streamType: "STATIC",
        editable: false,
      },
    ]);
  };
  const steps: { [key in StepsChoiceType]: Step } = {
    WRITE_QUESTION: {
      // init step
      sentMessage: true,
      onSendMessage: async function () {
        // move to answer question procedure
        setCurrentStepIndex(STEPS_CHOICES.ANSWER_QUESTION);
      },
      onStartStep: async function () {
        if (messages.length !== 0) return;
        // display welcome message in the chat
        initChat();
      },
    },

    ANSWER_QUESTION: {
      // answer using vector search
      sentMessage: false,
      onSendMessage: async function () {
        // Empty
      },
      onStartStep: async function () {
        setMessage("");
        // wait for answer
        // then allow user to ask question again
        // error/success handle will be inside SWR Hook
        await answerQuestionWithToolsTrigger();
        setCurrentStepIndex(STEPS_CHOICES.WRITE_QUESTION);
      },
    },
  };
  const currentStep = steps[currentStepIndex];

  const sendMesage = () => {
    setMessages([
      ...messages,
      {
        role: "user",
        content: message.trim(),
        left: false,
        isStreaming: false,
        streamType: "USER",
      },
    ]);
    currentStep.onSendMessage();
  };
  const scrollToBottom = () => bottomDiv.current?.scrollIntoView();
  useEffect(() => {
    currentStep.onStartStep();
  }, [currentStepIndex]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    initChat();
  }, [id]);

  return (
    <Box
      className={`${
        isVisible ? "flex" : "hidden"
      } px-2  w-full h-full overflow-clip flex flex-col  `}
    >
      {/* First Prompt */}
      <Box className="w-full h-[90%] flex flex-col border border-gray-300  rounded-sm shadow-lg justify-start gap-2 py-2 bg-white overflow-y-scroll scroll-smooth scrollbar-thumb-rounded-lg scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thin ">
        {/* need this dummy div to make chat messages to start from bottom */}
        <div className=" mt-auto"></div>
        {messages.map((message, index) => (
          <MessageItem
            key={`insight-message-${index}`}
            {...message}
            onStreamingEnd={() => {
              message.onStreamingEnd && message.onStreamingEnd();
              // make sure that re-render message
              // dont re start the animation
              setMessages((prev) =>
                prev.map((val, mapIndex) =>
                  mapIndex !== index ? val : { ...val, isStreaming: false }
                )
              );
            }}
            onReferenceClick={(ref) => setSelectedInterview(ref)}
          />
        ))}
        {/* dummy div to scroll down to */}
        <div className="mt-4" ref={bottomDiv}></div>
      </Box>
      <Box className="border-t relative  bg-white w-full h-[10%] rounded-t-md rounded-b-xl p-2 px-0 flex justify-center ">
        <textarea
          placeholder="Ask the experts..."
          className="resize-none rounded-2xl bg-white shadow-inner appearance-none border-2 border-gray-200  w-full py-2 px-4 text-gray-700 leading-tight outline-none pr-8 overflow-y-auto   focus:border-black"
          value={message}
          onChange={(ev) => setMessage(ev.target.value)}
          onKeyUpCapture={(e) => {
            if (!message.trim()) return;
            if (e.key === "Enter" && !e.shiftKey) {
              sendMesage();
            }
          }}
        />
        <Stack
          className="absolute right-0 top-1/2 p-2 px-4 transform  -translate-y-1/2"
          direction="row"
          justifyContent="center"
          alignItems="end"
        >
          <IconButton
            className="text-black hover:bg-black hover:text-white disabled:text-gray-500"
            disabled={!currentStep.sentMessage}
            onClick={() => {
              if (!message.trim()) return;
              sendMesage();
            }}
            aria-label="send message"
          >
            <SendIcon />
          </IconButton>
        </Stack>
      </Box>
      <Dialog
        // fullScreen
        maxWidth={"xl"}
        open={!!selectedInterview}
        onClose={() => setSelectedInterview(undefined)}
      >
        {selectedInterview && (
          <DialogContent>
            <InterviewDetails interview={selectedInterview} />
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
}

// moved this function to the outside of InsightChat
// to prevent extra render operation
function MessageItem(
  message: Message & { onReferenceClick?: (ref: CosmoInterview) => any }
) {
  if (message.visible === false) return null;

  return message.streamType === "STREAM" ? (
    <StreamedMessageItem
      {...message}
      isStreaming={message.isStreaming}
      editable={!message.isStreaming && message.editable}
      onEdit={(newText) => {
        message.onEdit && message.onEdit({ content: newText });
      }}
    />
  ) : (
    <AnimatedMessageItem
      {...message}
      animate={message.isStreaming}
      onEdit={(newText) =>
        message.onEdit && message.onEdit({ content: newText })
      }
      onAnimationEnd={() => message.onStreamingEnd && message.onStreamingEnd()}
      onRefrenceClick={(ref) =>
        message.onReferenceClick && message.onReferenceClick(ref)
      }
    />
  );
}
