import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { z } from "zod";
import { useAuthContext } from "../../hooks/useAuth";
import { FormIndexProps, LOGIN_PAGE_FORMS } from "../../pages/Login";
import { signUpWithEmail } from "../../services/auth";
import { signupInputSchema } from "../../zod/auth";
import { googleIcon } from "../icons";
import { useToast } from "../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";

export function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.ai-xperts.io/">
        AI_xperts
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
type SignupSchemaType = z.infer<typeof signupInputSchema>;
type SignupSchmaError = z.typeToFlattenedError<SignupSchemaType>["fieldErrors"];
export function SignUpForm({ changeForm }: FormIndexProps) {
  const { loginWithGoogle } = useAuthContext();
  const { setToast } = useToast();
  const [errors, setErrors] = useState<SignupSchmaError>({});
  const navigate = useNavigate();
  const { trigger: triggerSignUp, isMutating: isLogginUp } = useSWRMutation(
    "signup_email",
    async (
      _,
      {
        arg,
      }: {
        arg: {
          first_name: string;
          last_name: string;
          email: string;
          password: string;
        };
      }
    ) => {
      await signUpWithEmail(arg);
      return arg;
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        navigate(routes.ON_BOARD, { state: { ...data } });
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Wrong inputs provided";

        setToast({ message, open: true, severety: "error" });
      },
    }
  );
  const {
    isMutating: isGoogleLoading,
    trigger: triggerGoogleLogin,
  } = useSWRMutation(
    "login_with_google",
    async (_, { arg }: { arg: { access_token: string } }) =>
      loginWithGoogle(arg),
    {
      throwOnError: false,
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Wrong inputs provided";

        setToast({ message, open: true, severety: "error" });
      },
    }
  );
  const googleLogin = useGoogleLogin({
    onSuccess(tokenResponse) {
      triggerGoogleLogin(tokenResponse);
    },
    onError: (err) => {
      setToast({
        open: true,
        severety: "error",
        message:
          err.error_description ?? "Something went wrong try again later!",
      });
    },
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const signupForm = {
      first_name: data.get("firstName")?.toString() ?? "",
      last_name: data.get("lastName")?.toString() ?? "",
      email: data.get("email")?.toString() ?? "",
      password: data.get("password")?.toString() ?? "",
    };
    const parse = signupInputSchema.safeParse(signupForm);
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setErrors({});
    await triggerSignUp(parse.data);
  };
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Box className="flex flex-row justify-between gap-3">
          <FormControl fullWidth>
            <TextField
              fullWidth
              margin="normal"
              // required
              id="firstName"
              label="First Name"
              name="firstName"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.first_name?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              fullWidth
              margin="normal"
              // required
              id="lastName"
              label="Last Name"
              name="lastName"
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.last_name?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
        </Box>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.email?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.password?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.FORGETPASSWORD)}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.LOGIN)}
            >
              Already have an account? Sign In
            </Link>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          className="border-2 gap-2 mt-2"
          onClick={() => googleLogin()}
        >
          <SvgIcon>{googleIcon}</SvgIcon> Google
        </Button>
        <Copyright sx={{ mt: 5 }} />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLogginUp || isGoogleLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
