import { useParams } from "react-router-dom";
import { getProjectById } from "../services/project";
import useSWR from "swr";
import { getAllInterviewsByProject } from "../services/interview";

export function useProject() {
  const { id } = useParams();
  return useSWR(
    () => id,
    (key) => getProjectById(key),
    {
      throwOnError: false,
      errorRetryCount: 0,
      //   revalidateIfStale: false,
      //   revalidateOnFocus: true,
      //   revalidateOnReconnect: false,
      shouldRetryOnError: false,
    }
  );
}

export function useProjectInterviews() {
  const { data: project } = useProject();
  return useSWR(
    project ? [`api/interview/getall/`, project.id] : null,
    ([, projectId]) =>
      getAllInterviewsByProject({ projectId: projectId.toString() })
  );
}
