import { Delete, Edit } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Fab,
  Stack,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useProject } from "../../hooks/useProject";
import {
  deleteQuestion,
  Question,
  updateQuestion,
} from "../../services/project";
import useSWRMutation from "swr/mutation";
import { DeleteQuestionDialog } from "../project/NewQuestionDialog";
import { useToast } from "../../hooks/useToast";

export function QuestionCell({ question }: { question: Question }) {
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const { setToast } = useToast();

  const [editMode, setEditMode] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAngle, setEditedAngle] = useState("");
  const { data: project, mutate: mutateProject } = useProject();
  const {
    isMutating: isDeletingQuestion,
    trigger: deleteQuestionTrigger,
  } = useSWRMutation(
    "delete_question",
    async () => {
      await deleteQuestion({ questionId: question.id });
      return question.id;
    },

    {
      throwOnError: false,
      onSuccess() {
        mutateProject(
          {
            ...project,
            questions: project?.questions.filter(
              (quest) => quest.id !== question.id
            ),
          },
          { revalidate: false }
        );
        setToast({
          open: true,
          severety: "success",
          message: "Question has been deleted successfuly!",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while deleting question!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );
  const {
    isMutating: isUpdatingQuestion,
    trigger: updateQuestionTrigger,
  } = useSWRMutation(
    "update_question",
    async () => {
      //TODO: replace this trim with more sophisticated zod validation
      const newQuestion = editedQuestion.trim();
      const newAngle = editedAngle.trim();
      if (question.question === newQuestion && question.angle === newAngle)
        return;

      return updateQuestion({
        questionId: question.id,
        angle: newAngle,
        question: newQuestion,
      });
    },

    {
      onSuccess() {
        mutateProject(
          {
            ...project,
            questions: project?.questions.map((quest) =>
              quest.id === question.id
                ? {
                    ...question,
                    angle: editedAngle.trim(),
                    question: editedQuestion.trim(),
                  }
                : quest
            ),
          },
          { revalidate: true }
        );
        setToast({
          open: true,
          severety: "success",
          message: "Question has been updated successfuly!",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while updating the question!";
        setToast({
          open: true,
          severety: "error",
          message,
        });
      },
    }
  );
  const initEditMode = () => {
    setEditMode(true);
    setEditedQuestion(question.question);
    setEditedAngle(question.angle);
  };
  if (!project) return null;
  return (
    <Box className="max-w-xs min-w-[20rem] flex-shrink-0  flex flex-col gap-2 group">
      {editMode ? (
        <>
          <TextareaAutosize
            className="w-full  border-gray-300 outline-none border rounded-2xl p-2 overflow-hidden whitespace-pre-line text-start"
            disabled={isUpdatingQuestion}
            value={editedAngle}
            onChange={(event) => {
              setEditedAngle(event.target.value);
            }}
          />
          <TextareaAutosize
            className="w-full  border-gray-300 outline-none border rounded-2xl p-2 overflow-hidden whitespace-pre-line text-start"
            disabled={isUpdatingQuestion}
            value={editedQuestion}
            onChange={(ev) => {
              setEditedQuestion(ev.target.value);
            }}
          />
          <Box className="flex gap-2">
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="contained"
              onClick={async () => {
                await updateQuestionTrigger();
                setEditMode(false);
              }}
            >
              save changes
            </Button>
            <Button
              size="small"
              color="secondary"
              className="rounded-2xl"
              variant="outlined"
              onClick={() => setEditMode(false)}
            >
              cancel
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box className="flex">
            <Chip
              label={question.angle}
              variant="outlined"
              style={{ backgroundColor: "white" }}
            />
          </Box>
          <Typography textAlign={"start"} fontWeight={700}>
            {question.question}
          </Typography>
          {project.isManager && (
            <Stack direction={"row"} alignItems={"start"}>
              <Tooltip title="Edit">
                <Fab
                  className="opacity-0 group-hover:opacity-100 delay-500 transition-all duration-200"
                  style={{ transform: "scale(0.7)" }}
                  size="small"
                  color="secondary"
                  onClick={initEditMode}
                >
                  <Edit />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete">
                <Fab
                  className="opacity-0 group-hover:opacity-100 delay-500 transition-all duration-200"
                  style={{ transform: "scale(0.7)" }}
                  size="small"
                  color="secondary"
                  onClick={() => setIsConfirmDeleteOpen(true)}
                >
                  <Delete />
                </Fab>
              </Tooltip>
            </Stack>
          )}
        </>
      )}
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
      >
        <DialogTitle>Delete Question/Row</DialogTitle>
        <Box className="flex justify-center items-center p-6 bg-white rounded border ">
          <DeleteQuestionDialog
            question={question}
            onDelete={async () => {
              // triggere deletion
              deleteQuestionTrigger();
              // close dialog
              setIsConfirmDeleteOpen(false);
            }}
            onClose={() => {
              setIsConfirmDeleteOpen(false);
            }}
          />
        </Box>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDeletingQuestion || isUpdatingQuestion}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
