export const LanguageCode = {
  de: "German",
  en: "Global English",
  en_au: "Australian English",
  en_uk: "British English",
  en_us: "English (US)",
  es: "Spanish",
  fi: "Finnish",
  fr: "French",
  hi: "Hindi",
  it: "Italian",
  ja: "Japanese",
  ko: "Korean",
  nl: "Dutch",
  pl: "Polish",
  pt: "Portuguese",
  ru: "Russian",
  tr: "Turkish",
  uk: "Ukrainian",
  vi: "Vietnamese",
  zh: "Chinese",
} as const;
