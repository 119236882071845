import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import jwt_decode from "jwt-decode";
import {
  InviteInput,
  loginWithInvitation,
  blackListToken,
  confirmEmail,
  loginWithEmail,
  loginWithGoogleAccessToken,
  refreshToken,
} from "../services/auth";
import { useLocalStorage } from "./useLocalStorage";
import { mutate } from "swr";
import TagManager from "react-gtm-module";
import { getUserUsage } from "../services/user";

export type IAuth = {
  isAuth: boolean;
  accessToken?: string;
  refreshToken?: string;
  user?: User;
  login: (params: { email: string; password: string }) => Promise<any>;
  loginwithInvite: (params: InviteInput) => Promise<any>;
  confirmEmail: (params: { user_id: string; token: string }) => Promise<any>;
  loginWithGoogle: (params: { access_token: string }) => Promise<any>;
  canUpload: () => Promise<boolean>;
  refresh: () => Promise<any>;
  onDeleteUser: () => any;
  logout: () => Promise<any>;
};
export const AuthContext = createContext<IAuth>({} as IAuth);
export const useAuthContext = () => useContext(AuthContext);
export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useAuth();
  TagManager.dataLayer({ dataLayer: { user_id: auth.user?.id } });
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export type User = {
  email: string;
  id: number;
  roles: { id: number; roleName: string }[];
  profile?: {
    id: number;
    user: number;
    first_name: string;
    last_name: string;
  };
  first_login: boolean;
  is_staff: boolean;
  organization: Organization;
  subscription: Subscription | null;
  customer: Customer | null;
  trial_upload_interview_limit: number;
};
export type Organization = {
  id: number;
  name: string;
  description: string;
  domains: string[];
  type: "INDIV" | "COMP";
  created_at: string;
  updated_at: string;
};
export type Subscription = {
  id: string;
  object: string;
  application: any;
  application_fee_percent: any;
  automatic_tax: {
    enabled: boolean;
    liability: any;
  };
  billing_cycle_anchor: number;
  billing_cycle_anchor_config: any;
  billing_thresholds: any;
  cancel_at: any;
  cancel_at_period_end: boolean;
  canceled_at: any;
  cancellation_details: {
    comment: any;
    feedback: any;
    reason: any;
  };
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: any;
  default_payment_method: string;
  default_source: any;
  default_tax_rates: Array<any>;
  description: any;
  discount: any;
  ended_at: any;
  invoice_settings: {
    issuer: {
      type: string;
    };
  };
  items: {
    object: string;
    data: Array<{
      id: string;
      object: string;
      billing_thresholds: any;
      created: number;
      metadata: {};
      plan: {
        id: string;
        object: string;
        active: boolean;
        aggregate_usage: any;
        amount: number;
        amount_decimal: string;
        billing_scheme: string;
        created: number;
        currency: string;
        interval: string;
        interval_count: number;
        livemode: boolean;
        metadata: {};
        nickname: any;
        product: string;
        tiers_mode: any;
        transform_usage: any;
        trial_period_days: any;
        usage_type: string;
      };
      price: {
        id: string;
        object: string;
        active: boolean;
        billing_scheme: string;
        created: number;
        currency: string;
        custom_unit_amount: any;
        livemode: boolean;
        lookup_key: any;
        metadata: {};
        nickname: any;
        product: string;
        recurring: {
          aggregate_usage: any;
          interval: string;
          interval_count: number;
          trial_period_days: any;
          usage_type: string;
        };
        tax_behavior: string;
        tiers_mode: any;
        transform_quantity: any;
        type: string;
        unit_amount: number;
        unit_amount_decimal: string;
      };
      quantity: number;
      subscription: string;
      tax_rates: Array<any>;
    }>;
    has_more: boolean;
    total_count: number;
    url: string;
  };
  latest_invoice: string;
  livemode: boolean;
  metadata: {};
  next_pending_invoice_item_invoice: any;
  on_behalf_of: any;
  pause_collection: any;
  payment_settings: {
    payment_method_options: any;
    payment_method_types: any;
    save_default_payment_method: string;
  };
  pending_invoice_item_interval: any;
  pending_setup_intent: any;
  pending_update: any;
  plan: {
    id: string;
    object: string;
    active: boolean;
    aggregate_usage: any;
    amount: number;
    amount_decimal: string;
    billing_scheme: string;
    created: number;
    currency: string;
    interval: string;
    interval_count: number;
    livemode: boolean;
    metadata: {};
    nickname: any;
    product: string;
    tiers_mode: any;
    transform_usage: any;
    trial_period_days: any;
    usage_type: string;
  };
  quantity: number;
  schedule: any;
  start_date: number;
  status:
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid";
  test_clock: any;
  transfer_data: any;
  trial_end: any;
  trial_settings: {
    end_behavior: {
      missing_payment_method: string;
    };
  };
  trial_start: any;
};
export type Customer = {
  id: string;
  object: string;
  address: {
    city: any;
    country: string;
    line1: any;
    line2: any;
    postal_code: any;
    state: any;
  };
  balance: number;
  created: number;
  currency: string;
  default_source: any;
  delinquent: boolean;
  description: any;
  discount: any;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: any;
    default_payment_method: any;
    footer: any;
    rendering_options: any;
  };
  livemode: boolean;
  metadata: {};
  name: string;
  phone: any;
  preferred_locales: Array<string>;
  shipping: any;
  tax_exempt: string;
  test_clock: any;
};
export function useAuth(): IAuth {
  const [tokens, setTokens] = useLocalStorage<
    | {
        access: string;
        refresh: string;
      }
    | undefined
  >("auth", undefined);

  const [user, setUser] = useState<User | undefined>(
    tokens?.access ? jwt_decode<{ user: User }>(tokens.access).user : undefined
  );
  const [loginDate, setLoginDate] = useState(new Date());

  const onLogin = () => setLoginDate(new Date());
  useEffect(() => {
    if (!tokens) {
      setUser(undefined);
      return;
    }
    setUser(jwt_decode<{ user: User }>(tokens.access).user);
  }, [tokens]);
  return {
    isAuth: !!tokens,
    accessToken: tokens?.access,
    refreshToken: tokens?.refresh,
    user,
    login: async function (params: { email: string; password: string }) {
      const tokens = await loginWithEmail(params);
      setTokens(tokens);
      onLogin();
    },
    loginwithInvite: async function (params) {
      const tokens = await loginWithInvitation(params);
      setTokens(tokens);
      onLogin();
    },
    confirmEmail: async function (params) {
      const tokens = await confirmEmail(params);
      setTokens(tokens);
      onLogin();
    },
    loginWithGoogle: async function (params) {
      const tokens = await loginWithGoogleAccessToken(params);
      setTokens(tokens);
      onLogin();
    },
    refresh: async function () {
      const tokens = await refreshToken();
      setTokens(tokens);
    },
    onDeleteUser() {
      // clear all cache on logout
      mutate(() => true, undefined, { revalidate: false });
      setTokens(undefined);
    },
    logout: async () => {
      if (!tokens) return;
      try {
        // calculate session difference
        const diffMilliseconds = Math.abs(
          new Date().getTime() - loginDate.getTime()
        );
        //send session duration with the event
        TagManager.dataLayer({
          dataLayer: {
            event: "sign_out",
            session_duration: Math.floor(diffMilliseconds / 1000),
          },
        });

        await blackListToken({ refreshToken: tokens.refresh });
      } finally {
        // clear all cache on logout
        mutate(() => true, undefined, { revalidate: false });
        setTokens(undefined);
      }
    },
    canUpload: async function () {
      const status = user?.subscription?.status;
      const uploadInterviewCounts = (await getUserUsage())
        .upload_interview_counts;
      const uploadsLimit = user?.trial_upload_interview_limit ?? 0;
      return (
        status === "active" ||
        (status === "trialing" && uploadInterviewCounts < uploadsLimit)
      );
    },
  };
}
