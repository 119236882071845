import { useState, useEffect } from "react";

export const useLocalStorage = function <T>(key: string, defaultValue?: T) {
  // Create state variable to store
  // localStorage value in state
  const [localStorageValue, setLocalStorageValue] = useState<T>(() => {
    try {
      const value = localStorage.getItem(key);
      // If value is already present in
      // localStorage then return it
      // Else set default value in
      // localStorage and then return it
      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (error) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    }
  });
  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(() => {
        try {
          const value = localStorage.getItem(key);
          // If value is already present in
          // localStorage then return it
          // Else set default value in
          // localStorage and then return it
          if (value) {
            return JSON.parse(value);
          } else {
            localStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
          }
        } catch (error) {
          localStorage.setItem(key, JSON.stringify(defaultValue));
          return defaultValue;
        }
      });
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
    // eslint-disable-next-line
  }, []);
  // this method update our localStorage and our state
  const setLocalStorageStateValue = (valueOrFn: T) => {
    let newValue = valueOrFn;
    if (typeof valueOrFn === "function") {
      const fn = valueOrFn;
      newValue = fn(localStorageValue);
    } else {
      newValue = valueOrFn;
    }
    localStorage.setItem(key, JSON.stringify(newValue));
    setLocalStorageValue(newValue);
  };
  return [localStorageValue, setLocalStorageStateValue] as const;
};

export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new Event("storage"));
}
export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
  window.dispatchEvent(new Event("storage"));
}
export function getLocalStorage<T>(key: string): T | null {
  try {
    const value = localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : null;
  } catch (error) {
    return null;
  }
}
