import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { z } from "zod";
import {
  ProjectBackground,
  Questionnaire,
  createProject,
  getQuestionnaire,
  getQeustionnaireFromFileContent,
} from "../../services/project";
import {
  addQuestionToProjectInputSchema,
  createProjectInputSchema,
  projectInfoStepSchema,
} from "../../zod/project";
import { mutate } from "swr";
import { parse } from "best-effort-json-parser";
import { last } from "lodash";
import { PartialQuestion } from "../../services/project";
import { FileInputField } from "../input/FileUpload";
import { useToast } from "../../hooks/useToast";
import { Add, DeleteOutlined, DragIndicator, Sync } from "@mui/icons-material";

type QuestionRowType = { id: number } & PartialQuestion;

type NewProject = {
  projectName: string;
  angles: string;
  additionalBackground: string;
};

type ProjectInfoType = z.infer<typeof projectInfoStepSchema>;
type ProjectInfoErrorType = z.typeToFlattenedError<ProjectInfoType>["fieldErrors"];

const steps = [
  { label: "Project Info", validator: projectInfoStepSchema },
  { label: "Questionnaire", validator: projectInfoStepSchema },
];
export function CreateProjectForm() {
  const { setToast } = useToast();
  const [questions, setQuestions] = useState<QuestionRowType[]>([]);

  const navigate = useNavigate();
  const {
    data: questionTriggered,
    trigger: triggerGetQuesstionnaire,
    isMutating: isQuestionnaireLoading,
  } = useSWRMutation(
    "get_questonnaire",
    async () => {
      return getQuestionnaire({
        background: newProject,
        onDownloadProgress(progressEvent) {
          if (progressEvent.event.currentTarget.status !== 200) return;
          const { response } = progressEvent.event.currentTarget;
          if (!response) return;
          const { questions } = parse(response) as Questionnaire;
          if (!questions || !questions.length) return;
          setQuestions(
            questions
              .filter((partialQuestion) => partialQuestion.question)
              .map((partialQuestion, index) => {
                return {
                  ...partialQuestion,
                  id: index,
                };
              })
          );
        },
      });
    },
    {
      throwOnError: false,
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong while generating your questionnare! please try again later.";
        setToast({
          severety: "error",
          message,
          open: true,
        });
      },
    }
  );
  const {
    trigger: triggerCreateProjct,
    isMutating: isProjectBeingCreated,
  } = useSWRMutation(
    "create_project",
    async (
      _,
      { arg }: { arg: ProjectBackground & { questions: PartialQuestion[] } }
    ) => {
      return createProject({
        ...arg,
      });
    },
    {
      throwOnError: false,
      onSuccess(data) {
        mutate("get_all_projects");
        navigate({
          pathname: `/p/${data.id}`,
          search: createSearchParams({ sideWindow: "INTERVIEWS" }).toString(),
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please while creating your project! Please try again.";

        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    trigger: triggerExtractFileContent,
    isMutating: isExtractingContent,
  } = useSWRMutation(
    "extract_file_content",
    async (_, { arg: file }: { arg: File }) => {
      const formData = new FormData();
      formData.append("file", file);
      return await getQeustionnaireFromFileContent({
        formData,
        onDownloadProgress(progressEvent) {
          if (progressEvent.event.currentTarget.status !== 200) return;
          const { response } = progressEvent.event.currentTarget;
          if (!response) return;
          const { questions } = parse(response) as Questionnaire;
          if (!questions || !questions.length) return;
          setQuestions(
            questions
              .filter((partialQuestion) => partialQuestion.question)
              .map((partialQuestion, index) => {
                return {
                  ...partialQuestion,
                  id: index,
                };
              })
          );
        },
      });
    },
    {
      throwOnError: false,
      onSuccess(data) {
        const questions = data.questions.map((partialQuestion, index) => {
          return {
            ...partialQuestion,
            id: index,
          };
        });
        setQuestions([
          ...questions,
          { id: questions.length, angle: "", question: "" },
        ]);
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please while creating your project! Please try again.";

        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );

  const onCreateProject = async () => {
    // remove empty message at the end
    const questionToBeAdded = questions
      .slice(0, -1)
      //filter the rows withi no questions following #303
      .filter((val) => val.question.trim() !== "");

    for (let index = 0; index < questionToBeAdded.length; index++) {
      const parse = addQuestionToProjectInputSchema.safeParse(
        questionToBeAdded[index]
      );
      if (!parse.success) {
        setToast({
          message: `${
            (parse.error.flatten().fieldErrors.angle?.at(0)
              ? "Missing Angle: Please provide an Angle for every Question to create the project."
              : undefined) ??
            parse.error.flatten().fieldErrors.question?.at(0) ??
            ""
          }`,
          open: true,
          severety: "error",
        });
        return;
      }
    }
    const parse = createProjectInputSchema.safeParse({
      ...newProject,
      questions: questionToBeAdded,
    });
    if (parse.success) {
      setProjectInforErrors({});
      triggerCreateProjct({ ...parse.data });
      return;
    }
    console.log(parse.error.flatten().fieldErrors);
    setProjectInforErrors(parse.error.flatten().fieldErrors);
  };
  const [activeStep, setActiveStep] = useState(0);
  const [newProject, setNewProject] = useState<NewProject>({
    projectName: "",
    angles: "",
    additionalBackground: "",
  });
  const [
    projectInfoErrors,
    setProjectInforErrors,
  ] = useState<ProjectInfoErrorType>({});

  const goNextStep = () => {
    // TODO: call validators

    const parse = steps[activeStep].validator.safeParse(newProject);
    if (!parse.success) {
      setProjectInforErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setActiveStep(activeStep + 1);
    setProjectInforErrors({});
  };
  const goPreviousStep = () => setActiveStep(activeStep - 1);
  return (
    <Box className="p-4  w-full h-fit overscroll-y-auto flex flex-col  ">
      <Box className="flex w-full flex-col items-center gap-4">
        <Stepper activeStep={activeStep} className="w-[500px]" alternativeLabel>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <ProjectInfoForm
            projectInfo={newProject}
            setProjectInfo={setNewProject}
            error={projectInfoErrors}
          />
        )}
        {activeStep === 1 && (
          <QuestionnaireForm
            questions={questions}
            questionnaireInfo={newProject}
            setQuestionnaireInfo={setNewProject}
            error={projectInfoErrors}
            setQuestions={setQuestions}
            generateQuestionnaire={triggerGetQuesstionnaire}
            generateQuestionnaireFromFile={triggerExtractFileContent}
            disableRegenerate={isQuestionnaireLoading}
          />
        )}
        <Stack direction={"row"} gap={4}>
          <Button
            disabled={activeStep === 0}
            className="px-6 normal-case"
            variant="outlined"
            onClick={goPreviousStep}
          >
            Back
          </Button>
          <Button
            disabled={activeStep === steps.length - 1}
            className={`${
              activeStep === steps.length - 1 ? "hidden" : "flex"
            } px-6 normal-case`}
            variant="contained"
            color="secondary"
            onClick={goNextStep}
          >
            Next
          </Button>
          <Button
            disabled={
              activeStep !== steps.length - 1 ||
              isQuestionnaireLoading ||
              !questions.length
            }
            className={`${
              activeStep !== steps.length - 1 ? "hidden" : "flex"
            } px-6 normal-case`}
            variant="contained"
            color="secondary"
            onClick={onCreateProject}
          >
            Create Project
          </Button>
        </Stack>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProjectBeingCreated}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
const ProjectInfoForm = ({
  projectInfo,
  setProjectInfo,
  error,
}: {
  projectInfo: NewProject;
  setProjectInfo: (newProject: NewProject) => any;
  error: ProjectInfoErrorType;
}) => {
  return (
    <Box className="w-full flex flex-col px-2">
      <Typography
        variant="h6"
        textAlign={"start"}
        fontWeight={700}
        paddingBottom={5}
      >
        Add information about your project to get started
      </Typography>
      <TextInput
        title="Project name"
        description="A descript name acts as background for your AI analysis"
        placeholder="e.g. Impact of AI on qualitative data analysis"
        value={projectInfo.projectName}
        setValue={(newValue) =>
          setProjectInfo({ ...projectInfo, projectName: newValue })
        }
        error={error.projectName?.[0]}
      />

      <TextInput
        title="Topics"
        description="The topics you want to discuss and structure your questions around"
        placeholder="e.g. Expert background, Tools user, Impact"
        value={projectInfo.angles}
        setValue={(newValue) =>
          setProjectInfo({ ...projectInfo, angles: newValue })
        }
        error={error.angles?.[0]}
      />
    </Box>
  );
};

const QuestionnaireForm = ({
  questionnaireInfo,
  questions,
  setQuestionnaireInfo,
  error,
  setQuestions,
  generateQuestionnaire,
  generateQuestionnaireFromFile,
  disableRegenerate,
}: {
  questionnaireInfo: NewProject;
  setQuestionnaireInfo: (newProject: NewProject) => any;
  error: ProjectInfoErrorType;
  questions: QuestionRowType[];
  setQuestions: (newQuestions: QuestionRowType[]) => any;
  generateQuestionnaire: () => any;
  generateQuestionnaireFromFile: (file: File) => any;
  disableRegenerate: boolean;
}) => {
  const [choice, setChoice] = useState<
    "AI_GENERATED" | "UPLOAD_GENERATED" | undefined
  >();
  const [selectedFile, setSelectedFile] = useState<File>();

  return (
    <Box className="w-full flex flex-col px-2 gap-2">
      {choice === undefined && (
        <>
          <Typography
            variant="h6"
            textAlign={"start"}
            fontWeight={700}
            paddingBottom={5}
          >
            Choose how to add your questions. These are the quetions AI_xperts
            will help you answer
          </Typography>
          <Stack gap={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className="flex flex-col items-start normal-case"
              onClick={() => {
                setChoice("AI_GENERATED");
                questions.length === 0 && generateQuestionnaire();
              }}
            >
              <Typography fontWeight={700}>Generate with AI</Typography>
              <Typography color={"gray"} textAlign="start" fontSize={14}>
                AI_xperts will suggest a questionnaore based on your project
                infos
              </Typography>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className="flex flex-col items-start normal-case"
              onClick={() => setChoice("UPLOAD_GENERATED")}
            >
              <Typography fontWeight={700}>Upload own questionnaire</Typography>
              <Typography color={"gray"} textAlign="start" fontSize={14}>
                AI_xperts will extract questions from your file
              </Typography>
            </Button>
          </Stack>
        </>
      )}
      {choice === "AI_GENERATED" && (
        <>
          <Typography
            variant="h6"
            color={"gray"}
            textAlign={"start"}
            fontWeight={700}
            paddingBottom={5}
          >
            Here is a questionnaire draft based on your project info. These are
            the questions AI_xperts will help you analyse. Edit your questions
            below.
          </Typography>
          <Stack direction={"row"} alignItems="end" gap={2}>
            <TextInput
              title="Additional background"
              description="Optionally, you can provide additional background to refine the suggestion"
              placeholder="e.g. Interviews target mainly qualitative researches in Germany"
              value={questionnaireInfo.additionalBackground}
              setValue={(newValue) =>
                setQuestionnaireInfo({
                  ...questionnaireInfo,
                  additionalBackground: newValue,
                })
              }
              error={error.angles?.[0]}
            />
            <Tooltip title="Regenerate">
              <Fab
                color="secondary"
                size="small"
                className="mb-3"
                disabled={disableRegenerate}
                onClick={generateQuestionnaire}
              >
                <Sync />
              </Fab>
            </Tooltip>
          </Stack>
          <QuestionsTable
            questions={questions}
            setQuestions={setQuestions}
            disableAddQuestion={disableRegenerate}
          />
        </>
      )}
      {choice === "UPLOAD_GENERATED" && (
        <>
          <Typography
            variant="h6"
            color={"gray"}
            textAlign={"start"}
            fontWeight={700}
            paddingBottom={5}
          >
            Please upload your questionnaire. These are the questions AI_xperts
            will help you analyse. Edit your questions below.
          </Typography>
          <Stack direction={"row"} alignItems="center" gap={2}>
            <Box className="w-full">
              <FileInputField
                maxLength={1}
                fileTypes={["pdf", "txt", "pptx", "docx"]}
                onSelectFiles={(files) => setSelectedFile(files[0])}
                uploadMessage="Upload questionnaire's file"
              />
            </Box>
            <Tooltip title="Regenerate">
              <Fab
                color="secondary"
                size="small"
                disabled={disableRegenerate || !selectedFile}
                onClick={() => generateQuestionnaireFromFile(selectedFile!)}
              >
                <Sync />
              </Fab>
            </Tooltip>
          </Stack>
          <QuestionsTable
            questions={questions}
            setQuestions={setQuestions}
            disableAddQuestion={disableRegenerate}
          />
        </>
      )}
    </Box>
  );
};

const QuestionsTable = ({
  questions,
  setQuestions,
  disableAddQuestion,
}: {
  questions: QuestionRowType[];
  setQuestions: (newQuestions: QuestionRowType[]) => any;
  disableAddQuestion: boolean;
}) => {
  const reorder = <T,>(list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  return (
    <DragDropContext
      onDragEnd={(result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const orderedQuestions = reorder(
          questions,
          result.source.index,
          result.destination.index
        );
        setQuestions(orderedQuestions);
      }}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            ref={provided.innerRef}
            className="max-h-[600px] overflow-y-auto"
            style={{}}
          >
            <ListItem
              ref={provided.innerRef}
              className="flex flex-row p-0 border-b border-solid  border-gray-300"
            >
              <ListItemText className="m-0 p-4 min-w-[200px] max-w-[200px]">
                <Typography
                  color={"gray"}
                  textAlign={"start"}
                  fontSize={14}
                  fontWeight={600}
                  marginLeft={7}
                >
                  Angle
                </Typography>
              </ListItemText>
              <ListItemText className="m-0 p-4 ">
                <Typography
                  color={"gray"}
                  textAlign={"start"}
                  fontSize={14}
                  fontWeight={600}
                  marginLeft={7}
                >
                  Question
                </Typography>
              </ListItemText>
            </ListItem>
            {questions.map((question, index) => (
              <Draggable
                key={question.id}
                draggableId={question.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    style={{ ...provided.draggableProps.style }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flex flex-row p-0 border-b border-solid  border-gray-300"
                  >
                    <ListItemIcon>
                      <DragIndicator />
                    </ListItemIcon>
                    <ListItemText className="m-0 min-w-[200px] max-w-[200px]">
                      <TextField
                        margin="normal"
                        type="text"
                        className="m-0 w-full p-0 "
                        value={question.angle}
                        onChange={(ev) => {
                          question.angle = ev.target.value;
                          setQuestions([...questions]);
                        }}
                        autoComplete="off"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      />
                    </ListItemText>
                    <ListItemText className="m-0">
                      <TextField
                        margin="normal"
                        type="text"
                        className="m-0 w-full p-0 border-solid  border-gray-300"
                        value={question.question}
                        onChange={(ev) => {
                          question.question = ev.target.value;
                          setQuestions([...questions]);
                        }}
                        autoComplete="off"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                      />
                    </ListItemText>
                    <ListItemIcon
                      onClick={() => {
                        setQuestions(questions.filter((_, i) => index !== i));
                      }}
                    >
                      <DeleteOutlined />
                    </ListItemIcon>
                  </ListItem>
                )}
              </Draggable>
            ))}
            <ListItem disablePadding>
              <ListItemButton
                disabled={disableAddQuestion}
                onClick={() => {
                  const newId =
                    (last(questions.sort((a, b) => a.id - b.id))?.id ?? 0) + 1;
                  setQuestions([
                    ...questions,
                    { id: newId, question: "", angle: "" },
                  ]);
                }}
              >
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <ListItemText primary="Add question" />
              </ListItemButton>
            </ListItem>
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};
const TextInput = ({
  title,
  value,
  placeholder,
  setValue,
  description,
  error,
}: {
  value: string;
  setValue: (newValue: string) => any;
  placeholder?: string;
  title: string;
  description: string;
  error?: string;
}) => {
  return (
    <Box className="w-full flex flex-col">
      <Typography variant="h6" textAlign={"start"} fontWeight={700}>
        {title}
      </Typography>
      <Typography variant="subtitle1" textAlign={"start"} color="gray">
        {description}
      </Typography>
      <FormControl fullWidth>
        <TextField
          margin="normal"
          type="text"
          className="m-0"
          placeholder={placeholder}
          value={value}
          onChange={(ev) => {
            setValue(ev.target.value);
          }}
          autoComplete="off"
        />
        <FormHelperText
          sx={{
            color: "error.main",
          }}
        >
          {error ?? ""}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
