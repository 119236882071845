import { Dialog, Paper, PaperProps } from "@mui/material";
import { createContext, FC, ReactNode, useContext, useState } from "react";
import { AudioRecorder } from "../components/input/AudioRecorder";
import Draggable from "react-draggable";
import { useToast } from "./useToast";
export type IAudioRecorderContext = {
  isOpen: boolean;
  open: (projectId: string) => any;
};

export const AudioRecorderContext = createContext<IAudioRecorderContext>(
  {} as IAudioRecorderContext
);
export const useAudioRecorder = () => useContext(AudioRecorderContext);

export const AudioRecorderProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setToast } = useToast();
  const [isOpen, setOpen] = useState(false);
  const [projectId, setProjectId] = useState("");

  const open = (projectId: string) => {
    if (isOpen) {
      setToast({
        open: true,
        message: "There is already recorder open!",
        severety: "warning",
      });
      return;
    }
    setProjectId(projectId);
    setOpen(true);
  };

  return (
    <AudioRecorderContext.Provider value={{ isOpen, open }}>
      {children}
      <Dialog
        hideBackdrop
        disableEnforceFocus // Let the user focus on elements outside the dialog
        disableAutoFocus
        maxWidth={"sm"}
        open={isOpen}
        PaperComponent={PaperComponent}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "fit-content", // Ensures that the dialog is
          width: "fit-content", // exactly the same size as its contents
        }}
      >
        <AudioRecorder projectId={projectId} onClose={() => setOpen(false)} />
      </Dialog>
    </AudioRecorderContext.Provider>
  );
};

function PaperComponent(props: PaperProps) {
  return (
    <Draggable>
      <Paper {...props} style={{ margin: 0, maxHeight: "100%" }} />
    </Draggable>
  );
}
