import { FC } from "react";
import { Navigate, NavigateProps } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuth";
import useSWR from "swr";
import { getAllProject } from "../services/project";
import { LoadingScreen } from "../pages/Project";
import { routes } from "../App";

export const ProtectedRoute: FC<{
  condition: boolean;
  children: JSX.Element;
  to?: string | NavigateProps["to"];
}> = ({ condition, children, to = routes.HOME }) => {
  if (condition) {
    return <Navigate to={to} replace />;
  }

  return children;
};

export const NoAuthProtectedRoute: FC<{
  to?: string;
  children: JSX.Element;
}> = ({ children, to = routes.HOME }) => {
  const { isAuth } = useAuthContext();

  return (
    <ProtectedRoute condition={isAuth} to={to}>
      {children}
    </ProtectedRoute>
  );
};
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}
export const AuthProtectedRoute: FC<{
  to?: string;
  children: JSX.Element;
}> = ({ children, to = routes.HOME }) => {
  const { isAuth } = useAuthContext();

  return (
    <ProtectedRoute condition={!isAuth} to={to}>
      {children}
    </ProtectedRoute>
  );
};

export const SubscriptionProtectedRoute: FC<{
  to?: string;
  children: JSX.Element;
}> = ({ children, to }) => {
  const { user, isAuth } = useAuthContext();

  return (
    <ProtectedRoute
      condition={
        !isAuth ||
        (user?.subscription?.status !== "active" &&
          user?.subscription?.status !== "trialing")
      }
      to={to ?? routes.SUBSCRIPTION}
    >
      {children}
    </ProtectedRoute>
  );
};
export const CompanyProtectedRoute: FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const { user } = useAuthContext();
  const {
    data: projects,
    isLoading: projectLoading,
  } = useSWR("get_all_projects", async () =>
    (await getAllProject()).sort((a, b) => b.id - a.id)
  );

  if (!projects) return <LoadingScreen />;
  return (
    <ProtectedRoute
      condition={user?.organization.type !== "COMP"}
      to={
        projects.length === 0
          ? routes.WELCOME
          : routes.createProjectURL(projects[0].id)
      }
    >
      {children}
    </ProtectedRoute>
  );
};
