import { Add } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Welcome() {
  const navigate = useNavigate();
  return (
    <Box className="p-4  w-full h-full flex flex-col justify-center items-center ">
      {/* First Prompt */}
      <Box className="p-4 py-8 shadow-none min-w-[300px] w-[60%] ">
        <Typography variant="h5">
          Start by creating your first project
        </Typography>

        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          gap={"8px"}
          className="p-4"
        >
          <Button
            onClick={() => navigate("/createProject")}
            variant="contained"
            color="secondary"
            className="py-2  font-semibold text-white hover:bg-black  rounded-xl"
            startIcon={<Add />}
          >
            new project
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
