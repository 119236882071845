import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { z } from "zod";
import { useAuthContext } from "../../hooks/useAuth";
import { FormIndexProps, LOGIN_PAGE_FORMS } from "../../pages/Login";
import { signInInputSchema } from "../../zod/auth";
import { googleIcon } from "../icons";
import { Copyright } from "./SignUpForm";
import { useToast } from "../../hooks/useToast";
type LoginSchemaType = z.infer<typeof signInInputSchema>;
type LoginSchemaError = z.typeToFlattenedError<LoginSchemaType>["fieldErrors"];
export function LoginForm({ changeForm }: FormIndexProps) {
  const { login, loginWithGoogle } = useAuthContext();
  const { toast, setToast } = useToast();
  const [errors, setErrors] = useState<LoginSchemaError>({});
  const { trigger: triggerLogin, isMutating: isLogginIn } = useSWRMutation(
    "login",
    async (_, { arg }: { arg: { email: string; password: string } }) =>
      login(arg),
    {
      throwOnError: false,
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong!";

        setToast({ message, open: true, severety: "error" });
      },
    }
  );
  const {
    isMutating: isGoogleLoading,
    trigger: triggerGoogleLogin,
  } = useSWRMutation(
    "login_with_google",
    async (_, { arg }: { arg: { access_token: string } }) =>
      loginWithGoogle(arg),
    {
      throwOnError: false,
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong!";

        setToast({ message, open: true, severety: "error" });
      },
    }
  );
  const googleLogin = useGoogleLogin({
    onSuccess(tokenResponse) {
      triggerGoogleLogin(tokenResponse);
    },
    onError: (err) => {
      setToast({
        message: err.error_description ?? "Something went wrong!",
        open: true,
        severety: "error",
      });
    },
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginForm = {
      email: data.get("email")?.toString() ?? "",
      password: data.get("password")?.toString() ?? "",
    };
    const parse = signInInputSchema.safeParse(loginForm);
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    setErrors({});
    await triggerLogin(parse.data);
  };
  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            // required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.email?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <TextField
            margin="normal"
            // required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.password?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>

        <Button
          className="signin-button"
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.FORGETPASSWORD)}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              onClick={() => changeForm(LOGIN_PAGE_FORMS.SIGNUP)}
            >
              Don't have an account? Sign Up
            </Link>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          className="signin-button order-2 gap-2 mt-2"
          onClick={() => googleLogin()}
        >
          <SvgIcon>{googleIcon}</SvgIcon> Google
        </Button>
        <Copyright sx={{ mt: 5 }} />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLogginIn || isGoogleLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Toast */}
    </Box>
  );
}
