import { axios } from "../utils/axios";
/**
 * update information related to user
 *
 * @param params
 * @returns Promise<string>
 */
export async function updateUser(params: {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
}): Promise<string> {
  return (await axios.put("/api/user/update/", params)).data;
}

export async function deleteUser(): Promise<void> {
  return (await axios.delete("/api/user/delete/")).data;
}

export type UserUsage = { upload_interview_counts: number };
export async function getUserUsage(): Promise<UserUsage> {
  return (await axios.get("/api/user/usage/")).data;
}
