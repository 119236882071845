import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { useProject } from "../hooks/useProject";
import { InsightsTab } from "../components/project/InsightTab";

export function ProjectPage() {
  const { data: project, error } = useProject();

  if (error) {
    const message =
      typeof error?.response?.data === "string"
        ? error.response.data
        : "Something went wrong while fetching your data, please try to refresh this page. Contact the AI_xperts support if the problem persists!";
    return (
      <Container className="p-4 m-0 max-w-none !w-full h-full flex flex-col ">
        <Box className="p-8 w-full h-full flex flex-col justify-center items-center shadow-2xl rounded-md  border-2 bg-white">
          <ErrorOutline fontSize="large" color="error" />

          <Typography
            fontFamily={"monospace"}
            className="font-bold text-gray-400 text-3xl"
          >
            {message}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Box className="p-4 m-0  w-full h-full flex flex-col  ">
      <Box className="relative max-w-full h-full flex flex-col shadow-2xl rounded-md  border-2 bg-white">
        {!project ? <LoadingScreen /> : <InsightsTab />}
      </Box>
    </Box>
  );
}
export const LoadingScreen = () => (
  <Box className="w-full  flex flex-grow justify-center items-center">
    <CircularProgress className="" color="primary" />
  </Box>
);
