// Generated by ts-to-zod
import { z } from "zod";

export const forgetPasswordInputSchema = z.object({
  email: z.string().toLowerCase().email(),
});

export const signupInputSchema = z.object({
  first_name: z.string().trim().min(2).max(50),
  last_name: z.string().trim().min(2).max(50),
  email: z.string().toLowerCase().email(),
  password: z.string().min(8).max(50),
});

export const signInInputSchema = z.object({
  email: z.string().toLowerCase().email(),
  password: z.string(),
});

export const resetPasswordInputSchema = z
  .object({
    password: z.string().min(8).max(50),
    confirmPassword: z.string(),
  })
  .refine(({ confirmPassword, password }) => password === confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export const inviteInputSchema = z
  .object({
    firstName: z.string().trim().min(2).max(50),
    lastName: z.string().trim().min(2).max(50),
    password: z.string().min(8).max(50),
    confirmPassword: z.string(),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
