import axioss from "axios";
import { BACKEND_URL } from "./constants";
import { getLocalAccessToken, refreshToken } from "../services/auth";
import { removeLocalStorage, setLocalStorage } from "../hooks/useLocalStorage";

const instance = axioss.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (err) {
    const config = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && config.url !== "/api/auth/token/refresh/" && !config._retry) {
        config._retry = true;
        console.log(config);
        
        try {
          //refresh token here
          const tokens = await refreshToken();
          setLocalStorage("auth", tokens);
          config.headers.Authorization = `Bearer ${tokens.access}`;
          return instance.request(config);
        } catch (_error: any) {
          removeLocalStorage("auth");
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export const axios = instance;
