import {
  Check,
  CloudUpload,
  Error,
  FileUpload,
  UploadFile,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";

export type FileInputProps<T> = {
  fileTypes?: string[];
  maximumSize?: number;
  maxLength?: number;
  uploadMessage?: string;
  postUploadMessage?: string;
  successMessage?: string;
  onSelectFiles: (
    files: File[],
    setProgress: (progress: number) => any
  ) => Promise<T> | T; // return success message which will be passed to onUploadComplete
  onUploadCompleted?: (uploadResponse: T) => any;
  onUploadFailure?: (message: string) => any;
};
export function FileInputDialog<T>({
  fileTypes = ["jpeg", "png", "jpg"],
  maximumSize = 5e8, //5MB
  maxLength = 10,
  uploadMessage = "Upload interview files",
  onSelectFiles,
  onUploadCompleted,
  onUploadFailure,
}: FileInputProps<T>) {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [localFiles, setlocalFiles] = useState<
    { name: string; message: string; error: boolean }[]
  >();
  const [isError, setIsError] = useState<{
    status: string;
    message: string;
  } | null>(null);

  const uploadFile = async (files: File[]) => {
    try {
      const resp = await onSelectFiles(files, () => undefined);
      // //everything goes right
      onUploadCompleted && onUploadCompleted(resp);
    } catch (err: any) {
      setIsError({ status: "Error Uploading", message: "Try Again!" });
      const message =
        typeof err?.response?.data === "string" &&
        err?.response?.data.length < 500
          ? err.response.data
          : "Something went wrong while uploading your file please try again";
      onUploadFailure && onUploadFailure(message);
    }
  };
  const onVerifyFilesAndFilter = async (files: FileList) => {
    if (files.length > maxLength) {
      setIsError({
        status: "Max number of files exceeded",
        message:
          "Number of files selected passes the allowed number of files of " +
          maxLength,
      });
      setlocalFiles(undefined);
      return;
    }
    if (files.length === 1) {
      const file = files[0];

      if (fileTypes !== undefined) {
        //if file doesnt match file types passed in props
        if (!fileTypes.includes(file.name.split(".").at(-1) ?? " ")) {
          // setlocalFile(undefined);
          setIsError({
            status: "Wrong File Type",
            message: "The selected file type doesnt match the supported types.",
          });
          return;
        }
      }
      if (maximumSize) {
        //if selected file excede the maximum file size
        const fileSize = file.size;

        if (fileSize > maximumSize) {
          // setlocalFile(undefined);
          setIsError({
            status: "Invalid File Size",
            message: "Try another file with a smaller size.",
          });
          return;
        }
      }
    }

    const toBeUploadedFiles: File[] = [];
    const allFiles: { name: string; message: string; error: boolean }[] = [];
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (fileTypes !== undefined) {
        //if file doesnt match file types passed in props
        if (!fileTypes.includes(file.name.split(".").at(-1) ?? " ")) {
          // setlocalFile(undefined);
          allFiles.push({
            name: file.name,
            message: "This file's type is not supported.",
            error: true,
          });
          continue;
        }
      }
      if (maximumSize) {
        //if selected file excede the maximum file size
        const fileSize = file.size;
        totalSize += fileSize;

        if (totalSize > maximumSize) {
          setIsError({
            status: "Invalid Files Size",
            message: "Total files size exceeded the maximum limit.",
          });
          return;
        }
        if (fileSize > maximumSize) {
          // setlocalFile(undefined);
          allFiles.push({
            name: file.name,
            message: "This file's size is exceeds size limit.",
            error: true,
          });
          continue;
        }
      }
      allFiles.push({
        name: file.name,
        message: "",
        error: false,
      });
      toBeUploadedFiles.push(file);
    }

    setIsError(null);
    setlocalFiles(allFiles);
    toBeUploadedFiles.length > 0 && (await uploadFile(toBeUploadedFiles));
  };

  return (
    <Box
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
      onDrop={async (ev) => {
        ev.preventDefault();
        if (!ev.dataTransfer.files || ev.dataTransfer.files.length <= 0) return;
        await onVerifyFilesAndFilter(ev.dataTransfer.files);
      }}
      sx={{ bgcolor: "secondary.main" }}
      className={`cursor-pointer w-full h-full rounded  border border-solid ${
        isError
          ? "border-red-400 hover:border-red-900"
          : "border-gray-400 hover:border-blue-400"
      } text-white transition flex flex-col justify-start items-center px-4  min-h-[50px] overflow-y-auto   `}
      onClick={() => {
        imageInputRef.current?.click();
      }}
    >
      <input
        type={"file"}
        multiple={maxLength !== 1}
        className="hidden"
        ref={imageInputRef}
        onChange={async (ev) => {
          if (!ev.target.files || ev.target.files.length <= 0) return;
          await onVerifyFilesAndFilter(ev.target.files);
        }}
      />
      {/* there is an error */}
      {isError ? (
        <div className="flex justify-center flex-col px-4 ">
          <IconButton className="mb-2 hover:bg-transparent border-solid  text-red-500">
            <Error />
          </IconButton>
          <p className="w-full text-center text-xl font-semibold">
            {isError.status}
          </p>
          <p className="text-gray-700 text-center">
            {isError.message}
            <span className="cursor-pointer whitespace-nowrap text-red-600 text-xl hover:underline">
              Try Again?
            </span>
          </p>
          <Box className="flex justify-between ">
            {`Supported are files of type ${joinArrayWithAnd(
              fileTypes
            )} up to ${maximumSize / 1e6} MB.`}
          </Box>
        </div>
      ) : localFiles ? (
        // there is a selected file
        <>
          <div className="w-full gap-1 flex flex-col justify-start items-start">
            <span className="w-full text-start  font-semibold text-sm">
              Selected File(s):
            </span>
            {localFiles.map((file) => (
              <span className="break-words text-start w-full text-sm">
                <span>{file.name} </span>
                <span
                  className={`${
                    file.error ? "text-red-600" : "text-green-600"
                  }`}
                >
                  {file.message}
                </span>
                <Divider />
              </span>
            ))}
          </div>
          {/* <button className="text-gray-700 text-center p-2 mb-2 border rounded transition border-secondary hover:bg-secondary">
            Choose another file
          </button> */}
        </>
      ) : (
        // there is no file selected
        <Box className="flex flex-row justify-start items-center gap-2 w-full h-full">
          <FileUpload />
          <Typography fontSize={18} paddingX={1}>
            {uploadMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
export function FileInputField<T>({
  fileTypes = ["jpeg", "png", "jpg"],
  maximumSize = 5e8, //5MB
  maxLength = 10,
  uploadMessage = "Upload or drop your interview transcript or recording file.",
  postUploadMessage = "Processing...",
  successMessage = "",
  onSelectFiles: onUpload,
  onUploadCompleted,
  onUploadFailure,
}: FileInputProps<T>) {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [localFiles, setlocalFiles] = useState<
    { name: string; message: string; error: boolean }[]
  >();
  const [isError, setIsError] = useState<{
    status: string;
    message: string;
  } | null>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadFile = async (files: File[]) => {
    setUploading(true);
    try {
      const resp = await onUpload(files, setProgress);
      setUploading(false);
      setProgress(100);

      // //everything goes right
      onUploadCompleted && onUploadCompleted(resp);
    } catch (err: any) {
      setUploading(false);
      setIsError({ status: "Error Uploading", message: "Try Again!" });
      const message =
        typeof err?.response?.data === "string" &&
        err?.response?.data.length < 500
          ? err.response.data
          : "Something went wrong while uploading your file please try again";
      onUploadFailure && onUploadFailure(message);
    }
  };
  const onVerifyFilesAndFilter = async (files: FileList) => {
    if (files.length > maxLength) {
      setIsError({
        status: "Max number of files exceeded",
        message:
          "Number of files selected passes the allowed number of files of " +
          maxLength,
      });
      setlocalFiles(undefined);
      return;
    }
    if (files.length === 1) {
      const file = files[0];

      if (fileTypes !== undefined) {
        //if file doesnt match file types passed in props
        if (!fileTypes.includes(file.name.split(".").at(-1) ?? " ")) {
          // setlocalFile(undefined);
          setIsError({
            status: "Wrong File Type",
            message: "The selected file type doesnt match the supported types.",
          });
          return;
        }
      }
      if (maximumSize) {
        //if selected file excede the maximum file size
        const fileSize = file.size;

        if (fileSize > maximumSize) {
          // setlocalFile(undefined);
          setIsError({
            status: "Invalid File Size",
            message: "Try another file with a smaller size.",
          });
          return;
        }
      }
    }

    const toBeUploadedFiles: File[] = [];
    const allFiles: { name: string; message: string; error: boolean }[] = [];
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (fileTypes !== undefined) {
        //if file doesnt match file types passed in props
        if (!fileTypes.includes(file.name.split(".").at(-1) ?? " ")) {
          // setlocalFile(undefined);
          allFiles.push({
            name: file.name,
            message: "This file's type is not supported.",
            error: true,
          });
          continue;
        }
      }
      if (maximumSize) {
        //if selected file excede the maximum file size
        const fileSize = file.size;
        totalSize += fileSize;

        if (totalSize > maximumSize) {
          setIsError({
            status: "Invalid Files Size",
            message: "Total files size exceeded the maximum limit.",
          });
          return;
        }
        if (fileSize > maximumSize) {
          // setlocalFile(undefined);
          allFiles.push({
            name: file.name,
            message: "This file's size is exceeds size limit.",
            error: true,
          });
          continue;
        }
      }
      allFiles.push({
        name: file.name,
        message: "",
        error: false,
      });
      toBeUploadedFiles.push(file);
    }

    setIsError(null);
    setlocalFiles(allFiles);
    toBeUploadedFiles.length > 0 && (await uploadFile(toBeUploadedFiles));
  };
  const allowedFilesMessage = `Supported Formats are ${joinArrayWithAnd(
    fileTypes
  )} up to ${maximumSize / 1e6} MB.`;

  return (
    <Box
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
      }}
      onDrop={async (ev) => {
        ev.preventDefault();
        if (!ev.dataTransfer.files || ev.dataTransfer.files.length <= 0) return;
        await onVerifyFilesAndFilter(ev.dataTransfer.files);
      }}
      className={`min-h-[50px] rounded bg-gray-100 border border-dashed ${
        isError
          ? "border-red-400 hover:border-red-900"
          : "border-gray-400 hover:border-gray-700"
      } transition flex flex-row justify-start items-center px-4 cursor-pointer `}
      onClick={() => {
        imageInputRef.current?.click();
      }}
    >
      <input
        type={"file"}
        multiple={maxLength !== 1}
        className="hidden"
        ref={imageInputRef}
        onChange={async (ev) => {
          if (!ev.target.files || ev.target.files.length <= 0) return;
          await onVerifyFilesAndFilter(ev.target.files);
        }}
      />
      {/* there is an error */}
      {isError ? (
        <div className="flex justify-center items-center flex-row px-2 gap-1">
          <Error color="error" />
          <p className="text-start text-xl whitespace-nowrap font-semibold">
            {isError.status}
          </p>
          <Stack>
            <Typography textAlign={"start"} className="text-gray-700">
              {isError.message}
              <span className="cursor-pointer whitespace-nowrap text-red-600 text-lg hover:underline">
                {" "}
                Try Again?
              </span>
            </Typography>
            <Typography textAlign={"start"}>{allowedFilesMessage}</Typography>
          </Stack>
        </div>
      ) : localFiles ? (
        // there is a selected file
        <div className="px-2 text-lg gap-2 flex flex-row justify-center items-start">
          <span className="text-lg font-semibold">Selected File(s):</span>
          {localFiles.map((file) => (
            <span className="whitespace-pre-wrap">{file.name} </span>
          ))}
          <div className=" text-start">
            {uploading && progress < 100
              ? `Uploading...${progress}%`
              : uploading && progress >= 100
              ? postUploadMessage
              : successMessage}
          </div>
        </div>
      ) : (
        // there is no file selected
        <Box className="flex flex-row items-center gap-2">
          <UploadFile />
          <Stack>
            <Typography textAlign={"start"} fontWeight={700} fontSize={16}>
              {uploadMessage}
            </Typography>
            <Typography textAlign={"start"} color="gray">
              {allowedFilesMessage}
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
function joinArrayWithAnd(array: string[]) {
  if (array.length === 0) {
    return "";
  } else if (array.length === 1) {
    return array[0];
  } else {
    const lastElement = array.at(-1) ?? ""; // Remove the last element
    const joinedString = array.slice(0, -1).join(", ") + " and " + lastElement;
    return joinedString;
  }
}
