import {
  Box,
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  Stack,
  Tooltip,
  Zoom,
} from "@mui/material";
import useSWR from "swr";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getAllPublicInterviews } from "../../services/interview";
import { CosmoInterview } from "../../services/project";
import { TextCell } from "../project/TextCell";
import { useEffect, useState } from "react";
import { CancelOutlined, Sms, ZoomInOutlined } from "@mui/icons-material";
import { InterviewDetails } from "../project/InterviewDetails";
import { InsightChat } from "../project/InsightChat";

export const AllInterviewsTable = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const { data, isLoading } = useSWR(
    () => paginationModel,
    async ({ page, pageSize }) =>
      await getAllPublicInterviews({ page: page + 1, per_page: pageSize })
  );
  const { count, results } = data ?? {};
  const [rowCount, setRowCounts] = useState(count || 0);
  const [selectedInterview, setSelectedInterview] = useState<
    CosmoInterview | undefined
  >(undefined);
  const displayInterviewDetails = (interview: CosmoInterview) => {
    setSelectedInterview(interview);
  };
  const interviewsColumns: GridColDef<CosmoInterview>[] = [
    {
      field: "actions",
      headerName: " ",
      sortable: false,
      editable: false,
      filterable: false,
      groupable: false,
      hideable: false,
      disableColumnMenu: false,
      pinnable: false,
      hideSortIcons: true,
      maxWidth: 15,

      renderCell({ row }) {
        return (
          <Stack
            className="w-full h-full grow"
            direction={"row"}
            justifyContent={"center"}
            alignItems={"start"}
          >
            <Tooltip title="More details">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => displayInterviewDetails(row)}
              >
                <ZoomInOutlined fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 120,

      valueGetter: (params) => params.row.metadata.date,
      renderCell(params) {
        return <TextCell value={params.value} />;
      },
    },
    {
      field: "industry",
      headerName: "Industry",
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => params.row.metadata.industry,
      renderCell(params) {
        return <TextCell value={params.value} />;
      },
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => params.row.metadata.company,
      renderCell(params) {
        return <TextCell value={params.value} />;
      },
    },
    {
      field: "job_position",
      headerName: "Job Position",
      type: "string",
      minWidth: 150,
      flex: 0.5,
      valueGetter: (params) => params.row.metadata.job_position,
      renderCell(params) {
        return <TextCell value={params.value} />;
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "string",
      minWidth: 250,
      flex: 1,
      valueGetter: (params) => params.row.metadata.tags.join(", "),
      renderCell(params) {
        return <TextCell value={params.value} />;
      },
    },
  ];
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    if (!count) return;
    setRowCounts(count);
  }, [count]);
  return (
    <Box className="w-full  flex h-full overflow-y-auto ">
      <Box className="p-4 w-full  h-full  flex  flex-col justify-start gap-3 ">
        <Box className="flex flex-row gap-2 overflow-hidden  w-full h-full ">
          <Box className="w-full min-h-[200px] h-full relative overflow-auto">
            <Tooltip title="Chat with our AI Agent">
              <Zoom
                in={!isChatOpen}
                timeout={{ appear: 500, enter: 500, exit: 500 }}
                className="absolute right-2 bottom-20"
                children={
                  <Fab
                    onClick={() => {
                      setIsChatOpen(!isChatOpen);
                    }}
                    size="small"
                    color="secondary"
                    aria-label="open chat"
                  >
                    <Sms />
                  </Fab>
                }
              />
            </Tooltip>
            <DataGrid
              getRowHeight={() => "auto"}
              loading={isLoading}
              rows={results ?? []}
              columns={interviewsColumns}
              rowCount={rowCount}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              paginationMode="server"
              // hideFooter
            />
          </Box>
          <Box
            className={`${
              isChatOpen ? "w-1/2" : " w-0"
            } flex transition-all h-full relative`}
          >
            <Tooltip title="Close chat">
              <Zoom
                in={isChatOpen}
                timeout={{ appear: 500, enter: 500, exit: 500 }}
                className="absolute left-2 top-0 z-50"
                children={
                  <IconButton
                    onClick={() => {
                      setIsChatOpen(!isChatOpen);
                    }}
                    className="text-slate-700"
                  >
                    <CancelOutlined fontSize="inherit" className="bg-white" />
                  </IconButton>
                }
              />
            </Tooltip>
            {/* this condition necessary for reseting the chat and forcing a re render */}

            <Box className="w-full h-full ">
              <InsightChat key={"public-chat"} isVisible={isChatOpen} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        // fullScreen
        maxWidth={"xl"}
        open={!!selectedInterview}
        onClose={() => setSelectedInterview(undefined)}
      >
        <DialogContent>
          {selectedInterview && (
            <InterviewDetails
              interview={selectedInterview!}
              enableExtractingInterviews={true}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
