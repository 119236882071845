import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { updateUser } from "../../services/user";
import useSWRMutation from "swr/mutation";
import { updateUserSchema } from "../../zod/setting";
import { useAuthContext } from "../../hooks/useAuth";
import { z } from "zod";

type UpdateUserSchemaType = z.infer<typeof updateUserSchema>;
type UpdateUserError = z.typeToFlattenedError<UpdateUserSchemaType>["fieldErrors"];
export function ProfileSetting({
  onProfileUpdateFailure,
  onProfileUpdateSucces,
}: {
  onProfileUpdateSucces?: (message: string) => any;
  onProfileUpdateFailure?: (message: string) => any;
}) {
  const { user, refresh } = useAuthContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassWord] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<UpdateUserError>({});
  const { isMutating: isUpdating, trigger: triggerUpdateUser } = useSWRMutation(
    "update_user",
    (_key, { arg }: { arg: UpdateUserSchemaType }) => updateUser(arg),
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        refresh();
        setPassWord("");
        setConfirmPassword("");
        onProfileUpdateSucces && onProfileUpdateSucces(data);
      },
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        onProfileUpdateFailure && onProfileUpdateFailure(message);
      },
    }
  );
  const formUpdated =
    firstName !== user?.profile?.first_name ||
    lastName !== user?.profile?.last_name ||
    (!!password && password === confirmPassword);
  const initForm = () => {
    if (!user) return;
    setFirstName(user.profile?.first_name ?? "");
    setLastName(user.profile?.last_name ?? "");
    setEmail(user.email ?? "");
    setPassWord("");
    setConfirmPassword("");
  };
  useEffect(() => {
    initForm();
  }, [user]);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const parse = updateUserSchema.safeParse({
      ...(firstName !== user?.profile?.first_name && { firstName }),
      ...(lastName !== user?.profile?.last_name && { lastName }),
      ...(password && password === confirmPassword && { password }),
    });
    if (parse.success) {
      setErrors({});
      triggerUpdateUser(parse.data);
      return;
    }
    setErrors(parse.error.flatten().fieldErrors);
  };
  return (
    <Box
      className=" flex flex-col overflow-y-auto py-2 px-4 "
      component="form"
      onSubmit={handleSubmit}
    >
      <Typography fontSize={20} textAlign={"start"}>
        Profile Setting
      </Typography>
      <Divider />
      {isUpdating && <LinearProgress />}
      <Box className="h-full flex px-3 flex-col overflow-y-auto py-2">
        <Stack
          direction={"row"}
          justifyContent={"stretch"}
          alignItems={"stretch"}
          spacing={2}
        >
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={(ev) => setFirstName(ev.target.value)}
              error={Boolean(errors.firstName)}
              disabled={isUpdating}
              autoFocus
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.firstName?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              // required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={(ev) => setLastName(ev.target.value)}
              error={Boolean(errors.lastName)}
              disabled={isUpdating}
              autoFocus
            />
            <FormHelperText
              sx={{
                color: "error.main",
              }}
            >
              {errors.lastName?.at(0) ?? ""}
            </FormHelperText>
          </FormControl>
        </Stack>
        <TextField
          margin="normal"
          // required
          // fullWidth
          id="email"
          label="E-mail"
          name="email"
          autoComplete="email"
          type="email"
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          autoFocus
          disabled={true}
        />
        <FormControl fullWidth>
          <TextField
            margin="normal"
            // required
            // fullWidth
            id="password"
            label="New Password"
            name="password"
            autoComplete="password"
            type="password"
            value={password}
            onChange={(ev) => {
              setPassWord(ev.target.value);
              // reset password error
              setErrors({ ...errors, password: undefined });
            }}
            error={Boolean(errors.password)}
            disabled={isUpdating}
            autoFocus
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {errors.password?.at(0) ?? ""}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            margin="normal"
            // required
            // fullWidth
            id="confirmPassowrd"
            label="Confirm new password"
            name="confirmPassword"
            autoComplete="password"
            type="password"
            value={confirmPassword}
            onChange={(ev) => setConfirmPassword(ev.target.value)}
            error={!!password && password !== confirmPassword}
            disabled={isUpdating}
            autoFocus
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {!!password &&
              password !== confirmPassword &&
              "Confirmation password doesn't match the new password"}
          </FormHelperText>
        </FormControl>
      </Box>
      {/* <Divider /> */}
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"end"}
        paddingTop={2}
      >
        <Button
          variant="outlined"
          color="primary"
          disabled={!formUpdated || isUpdating}
          onClick={() => {
            initForm();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!formUpdated || isUpdating}
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
}
