import { Question } from "../../services/project";
import { MarkdownTypography } from "../MarkdownTypography";
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { z } from "zod";
import { addQuestionToProjectInputSchema } from "../../zod/project";

type AddQuestionSchemaType = z.infer<typeof addQuestionToProjectInputSchema>;
type AddQuestionSchemaError = z.typeToFlattenedError<AddQuestionSchemaType>["fieldErrors"];
export function NewQuestionDialog({
  onAdd,
  onClose,
}: {
  onAdd: (params: { angle: string; question: string }) => Promise<any> | any;
  onClose: () => Promise<any> | any;
}) {
  const [errors, setErrors] = useState<AddQuestionSchemaError>({});
  const [angle, setAngle] = useState("");
  const [question, setQuestion] = useState("");
  const onQuestionAdd = () => {
    const parse = addQuestionToProjectInputSchema.safeParse({
      angle,
      question,
    });
    if (!parse.success) {
      setErrors(parse.error.flatten().fieldErrors);
      return;
    }
    onAdd({ ...parse.data });
  };
  return (
    <Stack direction={"column"} spacing={3} className="px-4 min-w-[600px]">
      <FormControl fullWidth>
        <TextField
          id="outlined-uncontrolled"
          label="Angle"
          value={angle}
          onChange={(ev) => setAngle(ev.target.value)}
        />
        <FormHelperText
          sx={{
            color: "error.main",
          }}
        >
          {errors.angle?.at(0) ?? ""}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="outlined-uncontrolled"
          label="Question"
          value={question}
          onChange={(ev) => setQuestion(ev.target.value)}
        />
        <FormHelperText
          sx={{
            color: "error.main",
          }}
        >
          {errors.question?.at(0) ?? ""}
        </FormHelperText>
      </FormControl>
      <Stack direction={"row"} spacing={1} justifyContent={"end"}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={onQuestionAdd}>
          Add
        </Button>
      </Stack>
    </Stack>
  );
}

export function DeleteQuestionDialog({
  onDelete,
  onClose,
  question,
}: {
  question: Question;
  onDelete: () => Promise<any> | any;
  onClose: () => Promise<any> | any;
}) {
  return (
    <Stack direction={"column"} spacing={3} className="px-4 min-w-[600px]">
      <MarkdownTypography
        className=""
        textAlign={"start"}
        fontSize={16}
        text={`Please confirm the deletion of question **"${question.question}"**, the question will be removed permanently.`}
      />
      <Stack direction={"row"} spacing={1} justifyContent={"end"}>
        <Button
          variant="outlined"
          color="primary"
          onClick={async () => await onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={async () => await onDelete()}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
}
export function ConfirmationDialog({
  message,
  onConfirm,
  onCancel,
}: {
  message: string;
  onConfirm: () => Promise<any> | any;
  onCancel: () => Promise<any> | any;
}) {
  return (
    <Stack direction={"column"} spacing={3} className="px-4 min-w-[600px]">
      <MarkdownTypography
        className=""
        textAlign={"start"}
        fontSize={16}
        text={message}
      />
      <Stack direction={"row"} spacing={1} justifyContent={"end"}>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => await onConfirm()}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={async () => await onCancel()}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
