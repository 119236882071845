import {
  Add,
  CancelOutlined,
  ExpandMore,
  FileDownloadOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { unionBy, uniq } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import useSWRMutation from "swr/mutation";
import { useProject } from "../../hooks/useProject";
import { LoadingScreen } from "../../pages/Project";
import {
  addQuestionToProject,
  exportProjectToExcel,
} from "../../services/project";
import { NewQuestionDialog } from "./NewQuestionDialog";
import { QuestionCell } from "../insight/QuestionCell";
import { SummaryCell } from "../insight/SummaryCell";
import { AnswerCell } from "../insight/AnswerCell";
import { useToast } from "../../hooks/useToast";
import { InsightChat } from "./InsightChat";
import { InterviewsTab } from "./InterviewsTab";
import { ProjectSettingTab } from "./ProjectSettingTab";
import { useSearchParams } from "react-router-dom";

export const DEFAULT_SIDE_MENU_WIDTH = 320;

const windowTypeArray = ["CHAT", "INTERVIEWS", "SETTING", "NONE"] as const;
type WindowType = typeof windowTypeArray[number];
function isWindowType(window: string): window is WindowType {
  return windowTypeArray.includes(window as WindowType);
}

const Titles: { [key in WindowType]: string } = {
  SETTING: "Project Setting",
  INTERVIEWS: "Interviews",
  CHAT: "Assistant",
  NONE: "",
};
export const InsightsTab = () => {
  const { data: project, mutate: mutateProject } = useProject();
  const [isQuotesVisible, setIsQuotesVisible] = useState(true);
  const { setToast } = useToast();
  const [query] = useSearchParams();
  const [openedWindow, setOpenedWindow] = useState<WindowType>("NONE");
  useEffect(() => {
    //on first render
    //check query params value for side menu
    const sideMenu = query.get("sideWindow");
    if (sideMenu && isWindowType(sideMenu)) {
      setOpenedWindow(sideMenu);
    }
  }, []);

  const [isNewQuestDialogOpen, setIsNewQuestDialogOpen] = useState(false);
  const [selectedAngles, setSelectedAngles] = useState<string[] | undefined>();
  const [selectedQuestions, setSelectedQuestions] = useState<
    string[] | undefined
  >();
  const [selectedExperts, setSelectedExperts] = useState<
    string[] | undefined
  >();
  const [drawerWidth, setDrawerWidth] = useState(DEFAULT_SIDE_MENU_WIDTH);
  const [isResizing, setIsResizing] = useState(false);
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const insightTabRef = useRef<HTMLDivElement>(null);
  const handleMouseDown = () => {
    setIsResizing(true);
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const MIN_SIDE_MENU_WIDTH = 50;
    const MAX_SIDE_MENU_WIDTH = insightTabRef.current!.offsetWidth / 2;
    // prevent any selection during dragging
    window.getSelection()?.removeAllRanges();

    // calculate new width for sideMenu
    const newWidth =
      document.body.offsetWidth -
      (e.clientX - document.body.offsetLeft) -
      //this constant represt the extra space/padding/gap between the most right of the screen and sidmenu
      (document.body.offsetWidth -
        sideMenuRef.current!.getBoundingClientRect().right);
    if (newWidth >= MAX_SIDE_MENU_WIDTH) setDrawerWidth(MAX_SIDE_MENU_WIDTH);
    if (newWidth <= MIN_SIDE_MENU_WIDTH) setDrawerWidth(MIN_SIDE_MENU_WIDTH);
    if (newWidth > MIN_SIDE_MENU_WIDTH && newWidth < MAX_SIDE_MENU_WIDTH) {
      setDrawerWidth(newWidth);
    }
  }, []);
  const {
    isMutating: isAddingQuestion,
    trigger: addQuestionTrigger,
  } = useSWRMutation(
    "add_question_to_project",
    async (
      _,
      {
        arg: { angle, projectId, question },
      }: {
        arg: {
          angle: string;
          projectId: number;
          question: string;
        };
      }
    ) => {
      setIsNewQuestDialogOpen(false);
      await addQuestionToProject({ angle, projectId, question });
      await mutateProject();
    },

    {
      throwOnError: false,
      onSuccess(data) {
        console.log(data);
        setToast({
          message: "Question have been added successfully!",
          open: true,
          severety: "success",
        });
      },
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );
  const {
    isMutating: isPreparingExcelFile,
    trigger: exportProjectAsExcelTrigger,
  } = useSWRMutation(
    "export_project_excel",
    async (_) => {
      await exportProjectToExcel({ project: project! });
    },

    {
      throwOnError: false,
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        setToast({
          message,
          open: true,
          severety: "error",
        });
      },
    }
  );

  if (!project) {
    return <LoadingScreen />;
  }
  const questions = project.questions.slice().sort((a, b) => a.order - b.order);
  const angles = uniq(questions.map((qst) => qst.angle));
  const questionsTexts = questions.map((val) => val.question);
  const experts = uniq(
    questions
      .flatMap((val) => val.answers)
      .map(
        (answer) =>
          `${answer.interview.cosmo_doc.metadata.job_position} @${answer.interview.cosmo_doc.metadata.company}`
      )
  );

  const closeSideWindow = () => setOpenedWindow("NONE");
  const sideWindowHeader = (
    <Box className="w-full flex flex-row justify-between items-center pl-2">
      <Typography
        className="border-b-[2px] border-solid border-purple-600"
        fontWeight={700}
        fontSize={20}
      >
        {Titles[openedWindow]}
      </Typography>
      <Tooltip title="Close window">
        <IconButton onClick={closeSideWindow} className="text-slate-700">
          <CancelOutlined fontSize="inherit" className="bg-white" />
        </IconButton>
      </Tooltip>
    </Box>
  );
  const sideWindow = (
    <Box
      className={`${openedWindow !== "NONE" ? "border-gray-300" : ""} ${
        isResizing ? "transition-none" : "transition-all"
      } relative flex flex-col  h-full border-l border-solid gap-2 `}
      sx={{
        width: openedWindow !== "NONE" ? drawerWidth : 0,
        flexShrink: 0,
      }}
      ref={sideMenuRef}
    >
      {openedWindow !== "NONE" && sideWindowHeader}
      <Box
        className={`${
          openedWindow === "CHAT" ? "flex" : "hidden"
        } w-full h-full  overflow-y-auto`}
      >
        <InsightChat isVisible={openedWindow === "CHAT"} />
      </Box>
      <Box
        className={`${
          openedWindow === "INTERVIEWS" ? "flex" : "hidden"
        } w-full h-full overflow-y-auto`}
      >
        <InterviewsTab />
      </Box>
      <Box
        className={`${
          openedWindow === "SETTING" ? "flex" : "hidden"
        } w-full  overflow-y-auto`}
      >
        <ProjectSettingTab project={project} mutateProject={mutateProject} />
      </Box>
      <Box
        onMouseDown={handleMouseDown}
        className="cursor-ew-resize w-1 absolute left-0 top-0 bottom-0"
      ></Box>
    </Box>
  );
  const sideWindowControlPanel = (
    <Box className="flex flex-row justify-center items-center gap-1">
      {openedWindow !== "INTERVIEWS" && (
        <Tooltip
          title="Open interviews window"
          children={
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={`rounded-full normal-case text-sm`}
              onClick={() => setOpenedWindow("INTERVIEWS")}
              children={"Interviews"}
            />
          }
        />
      )}
      {openedWindow !== "CHAT" && (
        <Tooltip
          title="Open assistant chat"
          children={
            <Button
              variant="contained"
              className={`rounded-full normal-case text-sm`}
              color="secondary"
              size="small"
              onClick={() => setOpenedWindow("CHAT")}
              children={"Assistant"}
            />
          }
        />
      )}
      {openedWindow !== "SETTING" && (
        <Tooltip
          title="Open project setting"
          children={
            <IconButton
              className={`rounded-full normal-case text-sm`}
              size="small"
              onClick={() => setOpenedWindow("SETTING")}
              children={<SettingsOutlined />}
            />
          }
        />
      )}
    </Box>
  );

  return (
    <Box
      className="w-full relative  flex  h-full overflow-y-auto "
      ref={insightTabRef}
    >
      <Box className="p-4 w-full h-full  flex flex-row gap-2  justify-center items-center   ">
        <Box
          className={`${
            isResizing ? "transition-none" : "transition-all"
          } flex flex-col gap-2 overflow-hidden w-full  h-full`}
        >
          <Box className={`w-full h-full flex flex-col`}>
            <Box className="pb-2 flex flex-row justify-between gap-2">
              <Box className=" flex flex-row items-center gap-2">
                {project.isManager && (
                  <Box className="flex flex-row gap-2">
                    <Tooltip
                      title="Add new question"
                      children={
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="rounded-full px- normal-case text-sm"
                          onClick={() => setIsNewQuestDialogOpen(true)}
                          startIcon={<Add />}
                          children={"Question"}
                        />
                      }
                    />
                    <Tooltip
                      title="Export as Excel file"
                      children={
                        <IconButton
                          className="export-insights-xlxs-button rounded-full normal-case text-sm"
                          size="small"
                          onClick={() => {
                            exportProjectAsExcelTrigger();
                          }}
                          children={
                            <FileDownloadOutlined className="export-insights-xlxs-button" />
                          }
                        />
                      }
                    />
                    <Divider orientation="vertical" flexItem />
                  </Box>
                )}
                <InsightFilter
                  experts={experts}
                  angles={angles}
                  questions={questionsTexts}
                  isQuotesVisibile={isQuotesVisible}
                  quotesVisibilityToggle={() =>
                    setIsQuotesVisible(!isQuotesVisible)
                  }
                  onAnglesChange={setSelectedAngles}
                  onQuestionsChange={setSelectedQuestions}
                  onExpertsChange={setSelectedExperts}
                />
              </Box>
              {sideWindowControlPanel}
            </Box>
            <Box className="overflow-y-auto flex flex-col gap-2 items-start">
              {questions
                .filter(
                  (question) =>
                    selectedAngles === undefined ||
                    selectedAngles.includes(question.angle)
                )
                .filter(
                  (question) =>
                    selectedQuestions === undefined ||
                    selectedQuestions.includes(question.question)
                )
                .map((question) => (
                  // questioon
                  <Box
                    className="w-fit bg-gray-100 rounded p-4 flex flex-row flex-shrink-0 gap-2"
                    key={`question-${question.id}`}
                  >
                    <QuestionCell question={question} />
                    {/* Summary */}
                    {question.answers.length > 1 && (
                      <SummaryCell question={question} />
                    )}
                    {/* answers */}
                    {unionBy(question.answers, (val) => val.interview.id)
                      .sort((a, b) => b.id - a.id)
                      .filter(
                        (answer) =>
                          selectedExperts === undefined ||
                          selectedExperts.includes(
                            `${answer.interview.cosmo_doc.metadata.job_position} @${answer.interview.cosmo_doc.metadata.company}`
                          )
                      )
                      .map((answer) => (
                        <AnswerCell
                          key={`answer-${answer.id}`}
                          answer={answer}
                          displayQuotes={isQuotesVisible}
                        />
                      ))}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        {sideWindow}
      </Box>
      {/* toggelable screen on the side of table */}
      <Dialog
        open={isNewQuestDialogOpen}
        onClose={() => setIsNewQuestDialogOpen(false)}
      >
        <DialogTitle>Add new Question Form</DialogTitle>
        <Box className="flex justify-center items-center p-6 bg-white rounded border ">
          <NewQuestionDialog
            onAdd={async ({ angle, question }) =>
              addQuestionTrigger({
                angle,
                question,
                projectId: project.id,
              })
            }
            onClose={() => {
              setIsNewQuestDialogOpen(false);
            }}
          />
        </Box>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isAddingQuestion || isPreparingExcelFile}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

function InsightFilter({
  questions,
  angles,
  experts,
  isQuotesVisibile,
  quotesVisibilityToggle,
  onAnglesChange,
  onQuestionsChange,
  onExpertsChange,
}: {
  angles: string[];
  questions: string[];
  experts: string[];
  isQuotesVisibile: boolean;
  quotesVisibilityToggle: () => any;
  onAnglesChange: (newValues?: string[]) => any;
  onQuestionsChange: (newValues?: string[]) => any;
  onExpertsChange: (newValues?: string[]) => any;
}) {
  return (
    <Box className="flex flex-row gap-2">
      <MultipleSelect
        inverse={true}
        onSelectChange={onAnglesChange}
        label="Angles"
        texts={angles}
        disabled={angles.length === 0}
      />
      <MultipleSelect
        inverse={true}
        onSelectChange={onQuestionsChange}
        label="Questions"
        texts={questions}
        disabled={questions.length === 0}
      />
      <MultipleSelect
        inverse={true}
        onSelectChange={onExpertsChange}
        label="Experts"
        texts={experts}
        disabled={experts.length === 0}
      />
      <Stack direction="row" alignItems="center">
        <Typography fontSize={14}>Quotes</Typography>
        <Switch
          checked={isQuotesVisibile}
          onChange={quotesVisibilityToggle}
          inputProps={{ "aria-label": "Quotes" }}
        />
      </Stack>
    </Box>
  );
}
export default function MultipleSelect({
  texts,
  label,
  onSelectChange,
  inverse = false,
  disabled=false,
}: {
  texts: string[];
  label: string;
  onSelectChange: (selectedValues?: string[]) => any;
  inverse?: boolean;
  disabled?:boolean;
}) {
  const [selectedTexts, setSelectedTexts] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (inverse && selectedTexts.length === 0) {
      onSelectChange(undefined);
      return;
    }

    if (!inverse && selectedTexts.length === 0) {
      onSelectChange([...texts]);
      return;
    }
    onSelectChange(
      texts.filter((val) =>
        inverse ? !selectedTexts.includes(val) : selectedTexts.includes(val)
      )
    );
  }, [selectedTexts, inverse]);
  return (
    <>
      <Button
        className="normal-case"
        id="filter-button"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ExpandMore />}
        disabled={disabled}
      >
        {label}
      </Button>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filter-button",
          className: "max-w-lg",
        }}
      >
        {texts.map((name) => (
          <MenuItem
            className="w-full"
            key={name}
            value={name}
            onClick={() => {
              if (selectedTexts.includes(name)) {
                setSelectedTexts(selectedTexts.filter((val) => val !== name));
                return;
              }
              setSelectedTexts(uniq([...selectedTexts, name]));
            }}
          >
            <Checkbox
              checked={
                inverse
                  ? !selectedTexts.includes(name)
                  : selectedTexts.includes(name)
              }
            />
            <ListItemText primary={name} className=" whitespace-break-spaces" />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
