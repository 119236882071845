import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Link,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import useSWR from "swr";
import {
  getPlusPlanPriceId,
  getPaymentLink,
  getBillingPortalLink,
} from "../services/payment";
import { LoadingScreen } from "./Project";
import useSWRMutation from "swr/mutation";
import { useAuthContext } from "../hooks/useAuth";
import { dateDiffInDays } from "../utils/date";
import { Check } from "@mui/icons-material";
import { usePlusPlan } from "../hooks/usePlusPlan";

export function SubscriptionPage() {
  return <CheckoutForm />;
}

export const CheckoutForm = () => {
  const { user } = useAuthContext();
  const {
    plusPlanePriceId,
    isPlusPlanLoading,
    redirectToPaymentLink,
    isGeneratingLink,
  } = usePlusPlan();
  const { trigger: triggerGetBillingPortalLink } = useSWRMutation(
    "get_billin_portal_link",
    async () => getBillingPortalLink(),
    {
      throwOnError: false,
      onSuccess(data) {
        window.location.href = data;
      },
    }
  );

  const userInTrialMode = user?.subscription?.status !== "active";
  // ||
  //   user?.subscription?.status === "trialing" ||
  //   user?.subscription?.status === "canceled";
  const onClickPriceCard = () => {
    //generate url and redirect to there
    redirectToPaymentLink();
  };

  if (isPlusPlanLoading || !plusPlanePriceId) return <LoadingScreen />;

  return (
    <Box className="w-full min-h-full p-6 ">
      <Box className="w-full min-h-full px-4 py-6 border rounded-md flex flex-col gap-8 ">
        {user?.subscription?.status !== "active" &&
          user?.subscription?.status !== "trialing" && (
            <Typography className=" text-start px-8">
              🎉 Hey there! Hope you've had a blast exploring all the cool
              features of AI_xperts over the last 7 days! 😎
              <br />
              <br /> Looks like your free trial is wrapping up, but no worries —
              you can keep the magic going by jumping on our Plus plan. 🚀 Just
              a click away from more amazing features and continuous fun!
              <br />
              <br /> Got questions? Curious about promos? Hit us up at{" "}
              <a className="text-blue-600" href="mailto:sales@ai-xperts.io">
                sales@ai-xperts.io
              </a>{" "}
              — we're here to help!
              <br />
              <br /> <strong>Stay awesome! </strong>
              <br />
              <br />
              <strong>Your friends at AI_xperts 🌟</strong>
            </Typography>
          )}
        <Grid
          container
          gap={4}
          padding={4}
          alignItems="stretch"
          direction="row"
          justifyContent="center"
          // className="w-full flex gap-4 justify-start items-center flex-wrap"
        >
          {userInTrialMode && (
            <ProductCard
              title="Trial"
              buttonMessage=""
              buttonMessageOnSelect={`${
                user?.subscription?.status === "trialing"
                  ? dateDiffInDays(
                      new Date(),
                      new Date(
                        user?.subscription?.current_period_end! * 1000 ||
                          new Date()
                      )
                    )
                  : 0
              } days remaining`}
              descriptionTitle="Your free trial includes:"
              description={[
                "AI Assistant",
                "Project Insights",
                "Transcription & Summary",
                "Interview Library",
              ]}
              price={"EUR 0/month"}
              selected={true}
              onClick={() => {}}
            />
          )}
          <ProductCard
            title="Plus"
            buttonMessage="Upgrade to Plus"
            buttonMessageOnSelect="Your current plan"
            price={"EUR 30/month"}
            selected={!userInTrialMode}
            descriptionTitle="Unlimited usage of:"
            description={[
              "AI Assistant",
              "Project Insights",
              "Transcription & Summary",
              "Interview Library",
            ]}
            onClick={onClickPriceCard}
            footer={
              !userInTrialMode ? (
                <Link
                  color={"text.secondary"}
                  className="cursor-pointer mt-5 grow "
                  onClick={() => triggerGetBillingPortalLink()}
                >
                  Manage subscription and billing
                </Link>
              ) : undefined
            }
          />
          <ProductCard
            title="Enterprise"
            buttonMessage="Contact sales"
            buttonMessageOnSelect=""
            color="secondary"
            price={"EUR 50/month*"}
            descriptionTitle="Everything in Plus, and:"
            description={[
              "Customized AI Assistant",
              "Organization-wide Interview Library",
              "Training Sessions",
              "Priority Support",
            ]}
            selected={false}
            onClick={() =>
              window.open(
                "mailto:sales@ai-xperts.io?subject=Enterprise%20Subscription"
              )
            }
            footer={
              <Typography
                className="w-full text-xs mt-auto"
                textAlign={"start"}
                component="a"
                variant="subtitle1"
                color={"text.secondary"}
              >
                *Price billed annually, minimum 5 users
              </Typography>
            }
          />
        </Grid>
      </Box>
    </Box>
  );
};

const ProductCard = ({
  buttonMessage,
  buttonMessageOnSelect,
  price,
  title,
  selected,
  color = "primary",
  description,
  descriptionTitle,
  onClick,
  footer,
}: {
  title: string;
  price: number | string;
  buttonMessage: string;
  buttonMessageOnSelect: string;
  selected: boolean;
  color?:
    | "secondary"
    | "inherit"
    | "primary"
    | "success"
    | "error"
    | "info"
    | "warning";
  descriptionTitle: string;
  description: string[];
  onClick: () => any | Promise<any>;
  footer?: JSX.Element | string;
}) => {
  const { user } = useAuthContext();
  const PricingList = styled("ul")({
    margin: 0,
    padding: 0,
    listStyle: "none",
  });

  console.log(user?.subscription);
  // function getRightPrice() {
  //   return (
  //     product.prices.find((price) => {
  //       const reccurence = yearly ? "year" : "month";
  //       return price.recurring.interval === reccurence;
  //     }) ?? product.prices[0]
  //   );
  // }

  // const selectedPrice = getRightPrice();

  return (
    <Grid
      className="min-h-full border border-solid border-gray-200 rounded"
      minWidth={"280px"}
      maxWidth={"300px"}
      item
      xs={12}
      sm={2}
      md={2}
    >
      <Card className="h-full">
        <CardContent className="min-h-[360px] flex flex-col gap-3 justify-start">
          <Typography
            textAlign={"start"}
            component="h5"
            variant="h5"
            color="text.primary"
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "baseline",
              mb: 2,
            }}
          >
            <Typography component="p" color="text.secondry">
              {price}
            </Typography>
            {/* <Typography color="text.secondry">
              /{yearly ? "year" : "month"}
            </Typography> */}
          </Box>
          <CardActions
            sx={
              {
                // backgroundColor: (theme) => theme.palette.grey[200],
              }
            }
          >
            <Button
              variant="contained"
              color={color}
              fullWidth
              onClick={onClick}
              disabled={selected}
            >
              {selected ? buttonMessageOnSelect : buttonMessage}
              {/* {selected
                ? `${dateDiffInDays(
                    new Date(),
                    new Date(
                      user?.subscription?.current_period_end! * 1000 ??
                        new Date()
                    )
                  )} days remaining`
                : "Select Plan"} */}
            </Button>
          </CardActions>
          <Typography
            textAlign={"start"}
            component="h5"
            variant="h6"
            align="center"
          >
            {descriptionTitle}
          </Typography>
          <PricingList>
            {description.map((desc, index) => (
              <Box
                className="w-full flex gap-4 justify-start"
                key={`${title}-${index}`}
              >
                <Check />
                <Typography
                  textAlign={"start"}
                  component="li"
                  variant="subtitle1"
                  align="center"
                >
                  {desc}
                </Typography>
              </Box>
            ))}
          </PricingList>
          {footer}
        </CardContent>
      </Card>
    </Grid>
  );
};
