import { useState } from "react";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";
import { ProfileSetting } from "../components/setting/ProfileSetting";
import { DeleteAccountSetting } from "../components/setting/DeleteAccountSetting";
import { useToast } from "../hooks/useToast";

export function SettingPage() {
  const { toast, setToast } = useToast();
  const onProfileUpdateSucces = (message: string) =>
    setToast({
      message: message,
      open: true,
      severety: "success",
    });
  const onProfileUpdateFailure = (message: string) =>
    setToast({
      message: message,
      open: true,
      severety: "error",
    });
  const onUserDeletionFailure = onProfileUpdateFailure;
  return (
    <Box className="p-4 m-0  w-full h-full flex flex-col  ">
      <Box className="relative p-2 max-w-full h-full flex flex-col shadow-2xl rounded-md  border-2 bg-white">
        <Stack direction={"row"} justifyContent={"start"} alignItems={"center"}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <SettingsOutlined />
          </Avatar>
          <Typography component="h1" variant="h5" fontWeight={600}>
            Setting
          </Typography>
        </Stack>
        <Divider />
        <ProfileSetting
          onProfileUpdateSucces={onProfileUpdateSucces}
          onProfileUpdateFailure={onProfileUpdateFailure}
        />
        <DeleteAccountSetting onUserDeletionFailure={onUserDeletionFailure} />
      </Box>
    </Box>
  );
}
