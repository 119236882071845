import {
  CancelOutlined,
  CheckCircle,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { ProjectInterviewSuggestion } from "../../services/project";
import { useEffect, useState } from "react";
import { generateInsightsByInterviews } from "../../services/answer";
import useSWRMutation from "swr/mutation";
import { InterviewDetails } from "./InterviewDetails";
import { useToast } from "../../hooks/useToast";

export const SuggestionBox = ({
  suggestedInterview,
  onGenerateInsigts,
  onClose,
}: {
  suggestedInterview: ProjectInterviewSuggestion[];
  onGenerateInsigts: (ids: number[]) => Promise<any> | any;
  onClose: () => any;
}) => {
  const { setToast } = useToast();
  const [interviewsSuggestions, setInterviewsSuggestions] = useState<
    (ProjectInterviewSuggestion & { isChecked: boolean })[]
  >([]);
  useEffect(() => {
    // update suggested interview when project updated
    setInterviewsSuggestions(
      suggestedInterview
        .sort((a, b) => b.score - a.score)
        .map((val) => ({ ...val, isChecked: false }))
    );
  }, [suggestedInterview]);

  const {
    trigger: triggerInsightExtraction,
    isMutating: isExtracting,
  } = useSWRMutation(
    "extract_insights",
    async () => {
      const interviewIds = interviewsSuggestions
        .filter((val) => val.isChecked)
        .map((val) => parseInt(val.interview.db_id));
      // expirement with this
      // maybe using page params would be better
      if (interviewIds.length === 0) return [];
      const projectId = interviewsSuggestions[0].project;
      await generateInsightsByInterviews({
        interviewIds,
        projectId,
      });
      return interviewIds;
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {
        onGenerateInsigts(data);
        setToast({
          message: "Interview will be added to the project.",
          open: true,
          severety: "success",
        });
        setInterviewsSuggestions(
          interviewsSuggestions.filter((val) => !val.isChecked)
        );
      },
      onError(err, key, config) {
        setToast({
          message: "Something went wrong during extracting insights",
          open: true,
          severety: "error",
        });
      },
    }
  );

  return (
    <Box className="relative w-full h-full  flex  gap-2 flex-col p-4">
      <IconButton
        className="absolute top-2 right-2"
        onClick={onClose}
        color="primary"
      >
        <CancelOutlined />
      </IconButton>
      <Typography textAlign={"start"} fontWeight={700} fontSize={20}>
        Interview Suggestions
      </Typography>
      <Box className="w-full max-h-[300px] min-h-[300px] border p-1 shadow-gray-500/40 shadow-inner flex-grow flex flex-col gap-2 overflow-auto scroll-smooth scrollbar-thumb-rounded-lg scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thin">
        {interviewsSuggestions.length !== 0 ? (
          interviewsSuggestions.map((val, index) => (
            <InterviewItem
              interview={val}
              disabled={isExtracting}
              checked={val.isChecked}
              onClick={() => {
                setInterviewsSuggestions(
                  interviewsSuggestions.map((value, currentIndex) =>
                    currentIndex !== index
                      ? value
                      : { ...value, isChecked: !value.isChecked }
                  )
                );
              }}
            />
          ))
        ) : (
          <Typography
            textAlign={"center"}
            variant="h5"
            color={"gray"}
            className="w-full"
          >
            No Interview Found
          </Typography>
        )}
      </Box>
      <Box className="w-full flex justify-end">
        <Button
          disabled={
            isExtracting || !interviewsSuggestions.some((val) => val.isChecked)
          }
          variant="contained"
          color="secondary"
          onClick={() => triggerInsightExtraction()}
          className="flex gap-2 rounded-2xl"
        >
          <CircularProgress
            className={`${isExtracting ? "block" : "hidden"}`}
            size={20}
          />
          Add
        </Button>
      </Box>
    </Box>
  );
};

function InterviewItem({
  interview,
  disabled,
  checked,
  onClick,
}: {
  interview: ProjectInterviewSuggestion;
  disabled: boolean;
  checked: boolean;
  onClick: () => any;
}) {
  const [open, setOpen] = useState(false);
  return (
    <label className="w-full flex items-center">
      <Checkbox
        color="secondary"
        disabled={disabled}
        checked={checked}
        icon={<RadioButtonUnchecked />}
        checkedIcon={<CheckCircle />}
        onChange={onClick}
      />
      <Box className="w-full flex flex-row justify-between items-center select-none cursor-pointer">
        <Box className="flex flex-col">
          <Typography fontWeight={700} fontSize={16}>
            {`${interview.interview.metadata.job_position} @${interview.interview.metadata.company}`}
          </Typography>

          <Typography>{interview.interview.metadata.date}</Typography>
        </Box>
        <label className="flex justify-between items-center">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
            className="rounded-2xl"
          >
            Details
          </Button>
        </label>
      </Box>
      <Dialog
        // fullScreen
        maxWidth={"xl"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <InterviewDetails interview={interview.interview} />
        </DialogContent>
      </Dialog>
    </label>
  );
}
