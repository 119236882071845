import { axios } from "../utils/axios";

export type GetAllPricesResponse = Price[];

export type Price = {
  djstripe_id: number;
  product: Product;
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode: boolean;
  created: string;
  metadata: Metadata2;
  description: string;
  active: boolean;
  currency: string;
  nickname: string;
  recurring: Recurring;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  billing_scheme: string;
  lookup_key: string;
  tiers: any;
  tiers_mode: string;
  transform_quantity: any;
  djstripe_owner_account: string;
};

export type Metadata = {};

export type Product = {
  djstripe_id: number;
  djstripe_created: string;
  djstripe_updated: string;
  id: string;
  livemode: boolean;
  created: string;
  metadata: Metadata;
  description: string;
  name: string;
  type: string;
  active: boolean;
  attributes: any[];
  caption: string;
  deactivate_on: any;
  images: any[];
  package_dimensions: any;
  shippable: any;
  url: string;
  statement_descriptor: string;
  unit_label: string;
  djstripe_owner_account: string;
  default_price?: string;
};

export type Metadata2 = {};

export type Recurring = {
  aggregate_usage: any;
  interval: string;
  interval_count: number;
  trial_period_days: any;
  usage_type: string;
};

export async function getPlusPlanPriceId(): Promise<string> {
  return (await axios.get("/api/payment/getPlusPlanPriceId/")).data;
}
export async function getPaymentLink({
  priceId,
}: {
  priceId: string;
}): Promise<string> {
  return (
    await axios.get("/api/payment/getPaymentLink/", { params: { priceId } })
  ).data;
}

export async function verifyPayment({ sessionId }: { sessionId: string }) {
  return (
    await axios.get("/api/payment/verifyPayment/", {
      params: {
        sessionId,
      },
    })
  ).data;
}

export async function getBillingPortalLink(): Promise<string> {
  return (await axios.get("/api/payment/getBillingPortal/")).data;
}
