import { axios } from "../utils/axios";
import { CosmoInterview } from "./project";
import { Answer } from "./project";

export async function updateAnswer(params: {
  answerId: number;
  answer?: string;
  quotes?: string[];
}): Promise<Answer> {
  return (await axios.put("/api/answer/update/", params)).data;
}

export async function generateInsightsByInterviews({
  interviewIds,
  projectId,
}: {
  projectId: number;
  interviewIds: number[];
}): Promise<void> {
  return (
    await axios.post("/api/answer/answerProject/", { interviewIds, projectId })
  ).data;
}

export type InputPrompt = Prompt;
export type Prompt =
  | { role: "user"; content: string; references?: undefined }
  | {
      role: "assistant";
      content: string;
      references?: CosmoInterview[];
    };

export async function answerQuestionByToolCalls({
  messages,
  projectId,
  onMessageStream,
}: {
  messages: InputPrompt[];
  projectId?: number;
  onMessageStream: (prompt: Prompt) => any;
}): Promise<Prompt> {
  const parser = (dataString: string): Prompt => {
    const promptsArray = dataString
      .split("data: ")
      .filter((val) => val)
      .map((chunk) => JSON.parse(chunk) as Partial<Prompt>);

    const prompt: Prompt = { content: "", role: "assistant", references: [] };
    // merge chunks of promts togather
    for (let i = 0; i < promptsArray.length; i++) {
      const { content, references } = promptsArray[i];
      if (content) {
        prompt.content += content;
      }
      if (references && references.length > 0) {
        prompt.references = (prompt.references ?? []).concat(references);
      }
    }
    return prompt;
  };
  try {
    return parser(
      (
        await axios.post(
          "/api/answer/answerQuestionWithToolCalls/",
          {
            messages,
            projectId,
          },
          {
            onDownloadProgress: (progressEvent) => {
              let eventObj: XMLHttpRequest | undefined = undefined;
              if (progressEvent.event?.currentTarget) {
                eventObj = progressEvent.event?.currentTarget;
              } else if (progressEvent.event?.srcElement) {
                eventObj = progressEvent.event?.srcElement;
              } else if (progressEvent.event?.target) {
                eventObj = progressEvent.event?.target;
              }
              if (!eventObj) return;

              const dataChunk = eventObj.response as string;

              onMessageStream(parser(dataChunk));

              // console.log(dataChunk);
              // console.log(dataChunk.replace(lastMessageLength, ""));
              // const objChunk = JSON.parse(dataChunk.replace(lastMessageLength, ""));
              // lastMessageLength = dataChunk;
              // console.log(objChunk);
            },
          }
        )
      ).data
    );
  } catch (err) {
    console.log(err);
    return {} as any;
  }
}

export async function regenerateAnswer(params: {
  answerId: number;
}): Promise<void> {
  return (await axios.put("/api/answer/regenerate/", params)).data;
}
