import { TextareaAutosize } from "@mui/material";
import {
  GridRenderEditCellParams,
  GridValidRowModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useLayoutEffect, useRef } from "react";
import { MarkdownTypography } from "../MarkdownTypography";
export const TextCell = ({ value }: { value: string }) => {
  return (
    <div className="w-full h-full flex justify-start  ">
      <MarkdownTypography
        text={value}
        className="text-start whitespace-pre-line"
      />
    </div>
  );
};
export const TextAreaEditCell = <T extends GridValidRowModel>(
  props: GridRenderEditCellParams<T>
) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current?.focus();
    }
  }, [hasFocus]);

  return (
    <TextareaAutosize
      ref={ref}
      className="w-full outline-none border rounded-2xl p-1 overflow-hidden whitespace-pre-line text-start"
      value={value}
      onChange={(event) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
    />
  );
};
