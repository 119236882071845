import useSWR from "swr";
import { getPaymentLink, getPlusPlanPriceId } from "../services/payment";
import useSWRMutation from "swr/mutation";

export const usePlusPlan = () => {
  const { data: plusPlanePriceId, isLoading } = useSWR(
    "get_plus_plan_price",
    async () => getPlusPlanPriceId()
  );
  const { trigger: getPaymentLinkTrigger, isMutating } = useSWRMutation(
    "get_payment_link",
    async (_, { arg: { priceId } }: { arg: { priceId: string } }) =>
      getPaymentLink({ priceId }),
    {
      throwOnError: false,
      onSuccess(data) {
        window.location.href = data;
      },
    }
  );

  return {
    plusPlanePriceId,
    isPlusPlanLoading: isLoading,
    isGeneratingLink: isMutating,
    redirectToPaymentLink: () =>
      plusPlanePriceId && getPaymentLinkTrigger({ priceId: plusPlanePriceId }),
  };
};
