import { Alert, AlertColor, Button, Snackbar } from "@mui/material";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useState,
} from "react";

type Toast = {
  open: boolean;
  message: string;
  severety: AlertColor;
};
export type IToastContext = {
  toast: Toast;
  setToast: Dispatch<React.SetStateAction<Toast>>;
};

export const ToastContext = createContext<IToastContext>({} as IToastContext);
export const useToast = () => useContext(ToastContext);

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<Toast>({
    message: "",
    severety: "info",
    open: false,
  });

  return (
    <ToastContext.Provider value={{ toast, setToast }}>
      {children}
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setToast((prev) => ({ ...prev, open: false }));
        }}
      >
        <Alert
          onClose={() => {
            setToast((prev) => ({ ...prev, open: false }));
          }}
          severity={toast.severety}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
