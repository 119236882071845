import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import { LoginPage } from "./pages/Login";
import {
  AuthProtectedRoute,
  CompanyProtectedRoute,
  NoAuthProtectedRoute,
  SubscriptionProtectedRoute,
} from "./utils/routes";
import { VerifyEmail } from "./pages/VerifyEmail";
import { ResetPassword } from "./pages/ResetPassword";
import { ProjectPage } from "./pages/Project";
import { SideMenu } from "./components/SideMenu";
import { InvitePage } from "./pages/Invite";
import { LibraryPage } from "./pages/Library";
import { useEffect, useState } from "react";
import {
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  DialogTitle,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { SettingPage } from "./pages/Setting";
import styled from "@emotion/styled";
import { CreateProjectPage } from "./pages/CreateProject";
import { SubscriptionPage } from "./pages/Subscription";
import { SuccessPage } from "./pages/Success";
import { Onboard } from "./pages/Onboard";
import { useAuthContext } from "./hooks/useAuth";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Welcome } from "./pages/Welcome";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "black",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "black",
  },
}));
const TIME_LIMIT = 30000; // 30 seconds
const WelcomeDialog = () => {
  const { refresh, user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [timeLimitPassed, setTimeLimitPassed] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [query] = useSearchParams();

  useEffect(() => {
    const welcome = query.get("welcome");
    if (welcome === "1" || user?.first_login) {
      setOpen(true);
    }

    //set proper timeout
    const timeOut = setTimeout(() => {
      setTimeLimitPassed(true);
    }, TIME_LIMIT);

    return () => {
      //clean up
      //if user quickly closed popup before time is out
      clearTimeout(timeOut);
    };
  }, []);

  const closeHelpDialog = async () => {
    setOpen(false);
    await refresh();
  };
  const handleClose = () => {
    //check if set time interval passed
    // if not try to confirm tutorial skipp
    if (!timeLimitPassed) {
      //open confirmation menu
      setOpenConfirm(true);
      return;
    }
    //if enough time has passed just close the tutorial popup
    closeHelpDialog();
  };
  const confirmClose = () => {
    // user confirmed the closing of intro popup
    setOpenConfirm(false);
    closeHelpDialog();
  };

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <DialogContent className="flex flex-col gap-2 relative">
        <IconButton className="absolute top-2 right-2" onClick={handleClose}>
          <HighlightOffOutlined />
        </IconButton>
        <Typography fontSize={20}>
          Hi there 👋 Welcome aboard AI_xperts – glad to have you with us 🎉
        </Typography>{" "}
        <Typography fontSize={20}>
          This{" "}
          <strong>
            brief tutorial video will help you get started in no time
          </strong>{" "}
          🚀
        </Typography>
        <iframe
          className="mx-auto"
          width="1024"
          height="524"
          src="https://www.youtube.com/embed/2XY5jOASR-g?si=ABNINbQcX-Uvhi8A"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
        <Dialog
          maxWidth="lg"
          id={"close-help-popup"}
          open={openConfirm}
          onClose={() => setOpenConfirm(false)}
        >
          <DialogTitle>
            <Typography
              textAlign={"start"}
              fontSize={22}
              fontWeight={700}
              children={`Skip the onboarding? No problem!`}
            />
          </DialogTitle>
          <Box className="flex justify-center items-center p-6 bg-white rounded border ">
            <Stack
              direction={"column"}
              spacing={3}
              className="px-4 w-full min-w-[600px]"
            >
              <Typography
                textAlign={"start"}
                fontSize={20}
                children={`You can find this quick tutorial anytime in the Help & Support section (bottom left menu). It's worth a look before you dive in - it'll save you time!`}
              />
              <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenConfirm(false)}
                >
                  GO BACK
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={confirmClose}
                >
                  SKIP FOR NOW
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};
const SideBarLayout = () => {
  const [open, setOpen] = useState(true);
  return (
    <div className="flex w-full h-screen">
      <SideMenu open={open} />
      {/* rest of the page container */}
      <div
        className={`flex ${
          open ? "ml-96" : "ml-0"
        } grow pl-2 w-full h-full overflow-x-auto relative transition-all`}
      >
        <SideMenuTrigger open={open} setOpen={setOpen} />
        <Outlet />
      </div>
      <WelcomeDialog />
    </div>
  );
};

function SideMenuTrigger({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (param: boolean) => any;
}) {
  return (
    <BootstrapTooltip
      title={open ? "Close sidebar" : "Open sidebar"}
      placement="right"
    >
      <div
        onClick={() => setOpen(!open)}
        className="group z-[999] absolute top-1/2 -left-0 translate-x-1/2 -translate-y-1/2 transition-all opacity-25 hover:opacity-100  flex h-[72px] w-4 items-center justify-center"
      >
        <div className="flex h-6 w-6 flex-col items-center">
          <div
            className={`h-3 w-1 rounded-b-none rounded-full bg-black group-hover:rounded ${
              open
                ? "group-hover:rotate-[15deg]"
                : "group-hover:rotate-[-15deg]"
            }  translate-y-[0.08rem]`}
          ></div>
          <div
            className={`h-3 w-1 rounded-t-none rounded-full bg-black group-hover:rounded ${
              open
                ? "group-hover:rotate-[-15deg]"
                : "group-hover:rotate-[15deg]"
            } -translate-y-[0.08rem]`}
          ></div>
        </div>
      </div>
    </BootstrapTooltip>
  );
}

export const routes = {
  HOME: "/",
  CREATE_PROJECT: "/createProject",
  PROJECT: "/p/:id",
  createProjectURL: (id: string | number) => `/p/${id}`,
  LIBRARY: "/library",
  SETTING: "/setting",
  SUBSCRIPTION: "/subscription",
  LOGIN: "/login",
  VERIFY_EMAIL: "/verifyEmail",
  RESET_PASSWORD: "/resetPassword",
  INVITE: "/invite",
  SUCCESS: "/success",
  ON_BOARD: "/onboard",
  WELCOME: "/welcome",
} as const;
function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<SideBarLayout />}>
          <Route
            path={routes.HOME}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionProtectedRoute to={routes.SUBSCRIPTION}>
                  <CompanyProtectedRoute>
                    <LibraryPage />
                  </CompanyProtectedRoute>
                </SubscriptionProtectedRoute>
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.WELCOME}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionProtectedRoute to={routes.SUBSCRIPTION}>
                  <Welcome />
                </SubscriptionProtectedRoute>
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.CREATE_PROJECT}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionProtectedRoute to={routes.SUBSCRIPTION}>
                  <CreateProjectPage />
                </SubscriptionProtectedRoute>
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.PROJECT}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionProtectedRoute to={routes.SUBSCRIPTION}>
                  <ProjectPage />
                </SubscriptionProtectedRoute>
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.LIBRARY}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionProtectedRoute to={routes.SUBSCRIPTION}>
                  <CompanyProtectedRoute>
                    <LibraryPage />
                  </CompanyProtectedRoute>
                </SubscriptionProtectedRoute>
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.SETTING}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SettingPage />
              </AuthProtectedRoute>
            }
          />
          <Route
            path={routes.SUBSCRIPTION}
            element={
              <AuthProtectedRoute to={routes.LOGIN}>
                <SubscriptionPage />
              </AuthProtectedRoute>
            }
          />
        </Route>
        <Route
          path={routes.LOGIN}
          element={
            <NoAuthProtectedRoute>
              <LoginPage />
            </NoAuthProtectedRoute>
          }
        />
        <Route
          path={routes.VERIFY_EMAIL}
          element={
            <NoAuthProtectedRoute to="/?welcome=1">
              <VerifyEmail />
            </NoAuthProtectedRoute>
          }
        />
        <Route
          path={routes.RESET_PASSWORD}
          element={
            <NoAuthProtectedRoute>
              <ResetPassword />
            </NoAuthProtectedRoute>
          }
        />
        <Route
          path={routes.INVITE}
          element={
            <NoAuthProtectedRoute>
              <InvitePage />
            </NoAuthProtectedRoute>
          }
        />
        <Route
          path={routes.SUCCESS}
          element={
            <AuthProtectedRoute to={routes.LOGIN}>
              <SuccessPage />
            </AuthProtectedRoute>
          }
        />
        <Route path={routes.ON_BOARD} element={<Onboard />} />
        <Route path="*" element={<Navigate to={routes.HOME} />} />
      </Routes>
    </div>
  );
}
export default App;
