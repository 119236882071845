import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuth";
import useSWRMutation from "swr/mutation";

export function VerifyEmail() {
  const { confirmEmail, user } = useAuthContext();
  const [message, setMessage] = useState("Confirming email...");
  const [query] = useSearchParams();
  const { trigger: triggerConfirmEmail } = useSWRMutation(
    "confirm_email",
    async (
      _,
      {
        arg,
      }: {
        arg: {
          token: string;
          user_id: string;
        };
      }
    ) => {
      return confirmEmail(arg);
    },
    {
      throwOnError: false,
      onError(err) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Wrong inputs provided";
        setMessage(message);
      },
    }
  );
  useEffect(() => {
    const user_id = query.get("user_id");
    const token = query.get("token");
    if (!user_id || !token) {
      setMessage("There is some missing parameters! verify the link!");
      return;
    }
    triggerConfirmEmail({ user_id, token });
  }, []);
  return <div className="text-xl">{message}</div>;
}
