import { Edit, Save } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CosmoInterview } from "../services/project";
import { MarkdownTypography } from "./MarkdownTypography";

const Flicker = () => (
  <svg
    viewBox="8 4 8 16"
    xmlns="http://www.w3.org/2000/svg"
    className="animate-flicker mb-1 inline-block w-3 m-0"
  >
    <rect x="10" y="6" width="4" height="12" fill="#000000" />
  </svg>
);
const MESSAGE_ANIMATION_SPEED = 10;
export function AnimatedMessageItem({
  visible = true,
  content,
  left,
  animate,
  editable = false,
  references = [],
  onAnimationEnd,
  onEdit,
  onRefrenceClick,
}: {
  visible?: boolean;
  content: string;
  left: boolean;
  animate: boolean;
  editable?: boolean;
  onAnimationEnd?: () => any;
  onEdit?: (newText: string) => any;
  onRefrenceClick?: (ref: CosmoInterview) => any;
  references?: CosmoInterview[];
}) {
  const [displayedMessage, setDisplayedMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isAnimating, setIsAnimating] = useState(animate);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isEditing) {
      setDisplayedMessage(content);
    }
  }, [isEditing]);

  const editIcon = (
    <Tooltip
      contentEditable={false}
      title={isEditing ? "Save edited message" : "Edit message"}
      placement="right"
    >
      <IconButton
        className="absolute border-solid border border-black  -right-12 top-1/2 translate-x-1/4  m-1  text-blue-400 hover:bg-blue-400 hover:text-white"
        onClick={() => {
          if (isEditing) onEdit && onEdit(displayedMessage);
          setIsEditing(!isEditing);
        }}
        aria-label="send message"
      >
        {isEditing ? <Save /> : <Edit />}
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    if (!left || !animate) return;
    let i = 0;
    const intervalId = setInterval(() => {
      setDisplayedMessage(content.slice(0, i));
      i++;
      if (i > content.length) {
        clearInterval(intervalId);
        setIsAnimating(false);
        onAnimationEnd && onAnimationEnd();
      }
    }, MESSAGE_ANIMATION_SPEED);

    return () => {
      clearInterval(intervalId);
      // onAnimationEnd && onAnimationEnd();
    };
  }, []);
  const editField = (
    <div
      className={`${
        isEditing ? "w-full" : ""
      } relative outline-none border border-black rounded-2xl p-2 whitespace-pre-line text-start  ${
        left ? "" : " bg-gray-100"
      } `}
    >
      <TextareaAutosize
        value={displayedMessage}
        ref={textAreaRef}
        className={`${
          isEditing ? "block" : "hidden"
        } w-full relative outline-none border rounded-2xl p-1 overflow-hidden  text-start  ${
          left ? "bg-white text-black" : "border-black border-2 rounded-tr-none"
        } `}
        onKeyPress={(event) => {
          // if the key code is 13 (ENTER)
          if (!textAreaRef.current) return;

          if (event.key === "ENTER") {
            event.preventDefault(); // prevent usual browser behavour
            const currentPos = textAreaRef.current.selectionStart;

            const value = textAreaRef.current.value;
            const newValue =
              value.substring(0, currentPos) +
              "\n" +
              value.substring(currentPos);
            setDisplayedMessage(newValue);
            textAreaRef.current.setSelectionRange(currentPos, currentPos + 1);
          }
        }}
        onChange={(ev) => {
          setDisplayedMessage(ev.currentTarget.value);
        }}
      />

      <div
        className={`${
          !isEditing ? "flex" : "hidden"
        } flex-col  relative outline-none border border-transparent rounded-2xl p-1 overflow-hidden ${
          left
            ? "rounded-tl-none bg-white text-black"
            : "border-black border-2 rounded-tr-none"
        } `}
      >
        <MarkdownTypography
          text={content}
          sx={{ display: "flex", flexDirection: "column" }}
        />
        <div
          className={`${
            references.length ? "flex" : "hidden"
          } flex flex-wrap gap-2`}
        >
          <span>References: </span>
          {references.map((ref) => (
            <Chip
              onClick={() => onRefrenceClick && onRefrenceClick(ref)}
              sx={{
                height: "auto",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
              className="hover:bg-black hover:text-white"
              variant="outlined"
              color="primary"
              label={`${ref.metadata.job_position} @${ref.metadata.company}`}
            />
          ))}
        </div>
      </div>
      {editable && editIcon}
    </div>
  );
  const textField = isAnimating ? (
    <span
      className={`relative outline-none border border-black rounded-2xl p-2 whitespace-pre-line text-start max-w-[80%] ${
        left ? "" : "bg-gray-100"
      } `}
    >
      {displayedMessage}
    </span>
  ) : (
    editField
  );
  return (
    <Box
      className={`${visible ? "flex" : "hidden"} w-full flex   px-4  ${
        left ? "justify-start" : "justify-end"
      }`}
    >
      {textField}
    </Box>
  );
}
export function StreamedMessageItem({
  content,
  left,
  visible = true,
  isStreaming,
  editable = false,
  onEdit,
  onStreamingEnd,
}: {
  visible?: boolean;
  content: string;
  left: boolean;
  isStreaming: boolean;
  editable?: boolean;
  onEdit?: (text: string) => any;
  onStreamingEnd?: () => any;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempContent, setTempContent] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (isEditing) {
      setTempContent(content);
    }
  }, [isEditing]);
  useEffect(() => {
    if (!isStreaming) onStreamingEnd && onStreamingEnd();
  }, [isStreaming]);

  const editIcon = (
    <Tooltip
      contentEditable={false}
      title={isEditing ? "Save edited message" : "Edit message"}
      placement="right"
    >
      <IconButton
        className="absolute border-solid border border-black  -right-12 top-1/2 translate-x-1/4  m-1  text-blue-400 hover:bg-blue-400 hover:text-white"
        onClick={() => {
          if (isEditing) onEdit && onEdit(tempContent);
          setIsEditing(!isEditing);
        }}
        aria-label="send message"
      >
        {isEditing ? <Save /> : <Edit />}
      </IconButton>
    </Tooltip>
  );
  return (
    <Box
      className={`${visible ? "flex" : "hidden"} w-full flex  px-4  ${
        left ? "justify-start" : "justify-end"
      }`}
    >
      <div
        className={`${
          isEditing ? "w-full" : ""
        } relative outline-none border border-black rounded-2xl p-2 whitespace-pre-line text-start max-w-[80%] ${
          left ? "" : ""
        } `}
      >
        <TextareaAutosize
          value={tempContent}
          ref={textAreaRef}
          onKeyPress={(event) => {
            // if the key code is 13 (ENTER)
            if (!textAreaRef.current) return;

            if (event.key === "ENTER") {
              event.preventDefault(); // prevent usual browser behavour
              const currentPos = textAreaRef.current.selectionStart;

              const value = textAreaRef.current.value;
              const newValue =
                value.substring(0, currentPos) +
                "\n" +
                value.substring(currentPos);
              setTempContent(newValue);
              textAreaRef.current.setSelectionRange(currentPos, currentPos + 1);
            }
          }}
          className={`${
            isEditing ? "block" : "hidden"
          } w-full relative outline-none border black rounded-2xl p-1 overflow-hidden whitespace-pre-line text-start  ${
            left ? "" : ""
          } `}
          onChange={(ev) => {
            setTempContent(ev.currentTarget.value);
          }}
        />

        <div
          className={`${
            !isEditing ? "block" : "hidden"
          }  w-full  relative outline-none border border-transparent rounded-2xl p-1 overflow-hidden whitespace-pre-line text-start  ${
            left ? "" : ""
          } `}
        >
          {content}
          {isStreaming && <Flicker />}
        </div>
        {editable && !isStreaming && editIcon}
      </div>
    </Box>
  );
}
