import { Avatar } from "@mui/material";

export function StyledAvatar({ name }: { name: string }) {
  return (
    <Avatar
      variant="square"
      className="rounded-xl bg-white border-2 border-black text-black font-semibold"
      children={name}
    />
  );
}
