import { FFmpeg } from "@ffmpeg/ffmpeg";

export async function convertWebmToMp3(webmBlob: Blob): Promise<Blob> {
  const ffmpegInstance = new FFmpeg();
  ffmpegInstance.on("log", ({ message }) => {
    console.log("[ffmpeg]", message);
  });

  await ffmpegInstance.load();

  const inputName = "input.webm";
  const outputName = "output.mp3";
  const outputMimeType = "audio/mp3";
  ffmpegInstance.writeFile(
    inputName,
    new Uint8Array(await webmBlob.arrayBuffer())
  );

  await ffmpegInstance.exec(["-i", inputName, outputName]);
  const outputData = await ffmpegInstance.readFile(outputName);
  const data = new Uint8Array(outputData as ArrayBuffer);
  const outputBlob = new Blob([data.buffer], { type: outputMimeType });

  ffmpegInstance.terminate();
  return outputBlob;
}
