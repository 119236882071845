import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAuthContext } from "../../hooks/useAuth";
import useSWRMutation from "swr/mutation";
import { deleteUser } from "../../services/user";

export function DeleteAccountSetting({
  onUserDeletionFailure,
}: {
  onUserDeletionFailure?: (message: string) => any;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { user, onDeleteUser } = useAuthContext();
  const {
    isMutating: isDeletingUser,
    trigger: triggerUserDeletion,
  } = useSWRMutation(
    "delete_user",
    async () => {
      await deleteUser();
      onDeleteUser();
    },
    {
      throwOnError: false,
      onSuccess(data, key, config) {},
      onError(err, key, config) {
        const message =
          typeof err?.response?.data === "string" &&
          err?.response?.data.length < 500
            ? err.response.data
            : "Something went wrong please try again";
        onUserDeletionFailure && onUserDeletionFailure(message);
      },
    }
  );
  return (
    <Box className=" flex flex-col overflow-y-auto py-2 px-4 ">
      <Typography
        fontSize={24}
        fontWeight={500}
        textAlign={"start"}
        color={"error"}
      >
        Delete Account
      </Typography>
      <Divider />
      <Box className="p-2 flex flex-col">
        <Typography variant="body2" textAlign={"start"}>
          Once you delete your account, there is no going back. Please be
          certain.
        </Typography>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          paddingTop={2}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsDialogOpen(true)}
          >
            Delete your account
          </Button>
        </Stack>
      </Box>
      <Dialog
        open={isDialogOpen && !isDeletingUser}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>Are you sure you want to do this?</DialogTitle>
        <Box className="flex flex-col justify-center items-center p-6 bg-white rounded border ">
          <Typography width={"100%"} textAlign={"start"}>
            We will immediately delete all of your data. To verify your action,
            please insert your email.
          </Typography>
          <TextField
            margin="normal"
            // required
            fullWidth
            label="Your e-mail"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            // error={Boolean(errors.firstName)}
            // disabled={isUpdating}
            autoComplete="off"
            autoFocus
          />
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"end"}
            paddingTop={2}
            width={"100%"}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsDialogOpen(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setIsDialogOpen(false);
                triggerUserDeletion();
              }}
              disabled={email !== user?.email}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDeletingUser}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
